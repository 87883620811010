import React from 'react'
import { CategoryListMobile } from './mobile'
import { CategoryListDesktop } from './desktop'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

export const CategoryList = () => {
  const isMobile = useRecoilValue(clientIsMobile)

  if (isMobile) return <CategoryListMobile />
  return <CategoryListDesktop />
}
