import { createAxiosInstance } from 'common/config/axios.config'
import { DeleteResultResponse } from 'common/util/type'
import { CartModel, OrderModel } from 'domain/purchase/models'
import {
  CreateCartRequestDTO,
  CreateOrderProductRequestDTO,
  CreateOrderRequestDTO,
  UpdateCartRequestDTO,
} from 'domain/purchase/request.dto'
import { CartResponseDTO, OrderResponseDTO } from 'domain/purchase/response.dto'

const purchaseAxiosInstance = createAxiosInstance(true)

export function createCarts(
  createCartRequestDtos: CreateCartRequestDTO[],
): Promise<boolean> {
  return purchaseAxiosInstance
    .post<boolean>(`/purchase/carts`, { createCartRequestDtos })
    .then((response) => {
      return response.data
    })
}

export function updateCart(
  updateCartRequestDto: UpdateCartRequestDTO,
): Promise<CartModel> {
  return purchaseAxiosInstance
    .patch<CartResponseDTO>(
      `purchase/carts/${updateCartRequestDto.cartId}`,
      updateCartRequestDto,
    )
    .then((response) => {
      return new CartModel(response.data)
    })
}

export function deleteCarts(cartIds: number[]): Promise<DeleteResultResponse> {
  return purchaseAxiosInstance
    .delete<DeleteResultResponse>(`/purchase/carts`, { data: { cartIds } })
    .then((response) => {
      return response.data
    })
}

export function createOrder(
  createOrderRequestDto: CreateOrderRequestDTO,
  createOrderProductRequestDtos: CreateOrderProductRequestDTO[],
): Promise<OrderModel> {
  return purchaseAxiosInstance
    .post<OrderResponseDTO>(`/purchase/order`, {
      createOrderRequestDto,
      createOrderProductRequestDtos,
    })
    .then((response) => {
      return new OrderModel(response.data)
    })
}
