import {
  Flex,
  ButtonGroup,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MoneyText } from 'views/component/MoneyText'
import { AddBasketModal } from './AddBasketModal'
import { useFormikContext } from 'formik'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { useCreateCarts } from 'common/api/domains/purchase/purchaseMutation'
import { CreateCartRequestDTO } from 'domain/purchase/request.dto'
import { OrderProductForm } from 'domain/purchase/forms'
import { ProductModel } from 'domain/goods/model'
import { useSetRecoilState } from 'recoil'
import {
  orderProductFormsState,
  isOrderFromCartState,
} from 'state/purchase/atoms'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'common/util/string-utils'
import { useAuth } from 'common/hooks/useAuth'
import { CustomModal } from 'views/component/CustomModal'

type CostSummaryProps = {
  product: ProductModel
}

export const CostSummary = ({ product }: CostSummaryProps) => {
  const { user } = useAuth()
  const { values } = useFormikContext<CreateCartRequestDTO>()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { goTo: goToOrder } = usePrivateNavigate('/purchase/order')
  const { mutate: createCarts } = useCreateCarts()
  const setOrderProductForms = useSetRecoilState(orderProductFormsState)
  const setIsOrderFromCart = useSetRecoilState(isOrderFromCartState)
  const navigate = useNavigate()

  const [isCustomModalOpen, setCustomModalOpen] = useState(false)
  const [customModalAction, setCustomModalAction] = useState<() => void>(
    () => {},
  )
  const handleModalClose = () => {
    setCustomModalOpen(false)
  }
  const handleModalSubmit = () => {
    customModalAction()
    setCustomModalOpen(false)
  }

  const onClickAddBasket = async () => {
    console.log('testest')

    if (
      isEmpty(localStorage.getItem('jwt-token')) ||
      isEmpty(localStorage.getItem('refresh-token')) ||
      isEmpty(localStorage.getItem('user')) ||
      isEmpty(localStorage.getItem('phone'))
    ) {
      setCustomModalAction(() => () => navigate('/auth/login'))
      setCustomModalOpen(true)
    } else {
      await createCarts([{ ...values, userId: user?.id || -1 }])
      onOpen()
    }
  }

  const onClickOrder = () => {
    console.log(
      'testest',
      isEmpty(localStorage.getItem('jwt-token')) ||
        isEmpty(localStorage.getItem('refresh-token')) ||
        isEmpty(localStorage.getItem('user')) ||
        isEmpty(localStorage.getItem('phone')),
    )

    if (
      isEmpty(localStorage.getItem('jwt-token')) ||
      isEmpty(localStorage.getItem('refresh-token')) ||
      isEmpty(localStorage.getItem('user')) ||
      isEmpty(localStorage.getItem('phone'))
    ) {
      setCustomModalAction(() => () => navigate('/auth/login'))
      setCustomModalOpen(true)
    } else {
      const orderProductForms: OrderProductForm[] = [
        {
          count: values.count,
          productId: product.id,
          productName: product.name,
          price: product.price,
          unit: product.unit,
        },
      ]
      setOrderProductForms(orderProductForms)
      setIsOrderFromCart(false)
      goToOrder()
    }
  }

  if (!product) return null
  return (
    <>
      <Text fontSize={18} fontWeight={'bold'} textAlign={'right'} mt={4}>
        НИЙТ ДҮН(ШИРХЭГ) {/*총 구매금액(수량)*/}
      </Text>
      <Flex justifyContent={'end'} mb={4}>
        <MoneyText
          amount={product.price * values.count}
          unit={'₩'}
          suffix={`(${values.count}Ш)`}
          textProps={{ fontSize: 20, fontWeight: 'bold', color: '#005E9E' }}
        />
      </Flex>
      <Button
        size={'lg'}
        colorScheme="blue"
        onClick={values.count === 0 ? () => {} : onClickOrder}
        my={4}
      >
        ХУДАЛДАН АВАХ {/*구매하기*/}
      </Button>
      <Button
        size={'lg'}
        colorScheme="blue"
        variant={'outline'}
        onClick={values.count === 0 ? () => {} : onClickAddBasket}
      >
        САГСАНД НЭМЭХ {/*장바구니 담기*/}
      </Button>
      <AddBasketModal isOpen={isOpen} onClose={onClose} />
      fadsfdasfs
      <CustomModal
        isOpen={isCustomModalOpen}
        onClose={handleModalClose}
        submit={handleModalSubmit}
        message="НЭВТРЭХ ШААРДЛАГАТАЙ. ТА НЭВТЭРМЭЭР БАЙНА УУ?" /*로그인이 필요한 서비스입니다. 로그인 하시겠습니까?*/
        submitButtonMessage="ЗА" /*예*/
        closeButtonMessage="ҮГҮЙ" /*아니오*/
      />
    </>
  )
}
