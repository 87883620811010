import { Button, Divider, Flex, Text } from '@chakra-ui/react'
import { useGetCartIds } from 'common/api/domains/purchase/purchaseQuery'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { OrderProductForm } from 'domain/purchase/forms'
import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  cartCostState,
  cartsState,
  isOrderFromCartState,
  orderProductFormsState,
  selectedCartIdsState,
} from 'state/purchase/atoms'
import { MoneyText } from 'views/component/MoneyText'

type BasketAddonProps = {}

export const BasketAddon = ({}: BasketAddonProps) => {
  const { data: cartIds } = useGetCartIds()
  const { goTo: goToOrder } = usePrivateNavigate('/purchase/order')
  const carts = useRecoilValue(cartsState)
  const selectedCartIds = useRecoilValue(selectedCartIdsState)
  const cost = useRecoilValue(cartCostState)
  const setOrderProductForms = useSetRecoilState(orderProductFormsState)
  const setIsOrderFromCart = useSetRecoilState(isOrderFromCartState)

  const onSubmit = (all: boolean) => {
    if (!cartIds) return
    const orderProductForms = cartIds
      .filter((cartId) => (all ? true : selectedCartIds.includes(cartId)))
      .map((cartId) => {
        return carts[cartId].getOrderProductForm() as OrderProductForm
      })
    setOrderProductForms(orderProductForms)
    setIsOrderFromCart(true)
    goToOrder()
  }

  return (
    <Flex flexDirection={'column'}>
      <Flex
        w={'sm'}
        h={'fit-content'}
        backgroundColor={'whitesmoke'}
        flexDirection={'column'}
        p={4}
        mb={4}
      >
        <Text fontWeight={'bold'} fontSize={'lg'} mb={2}>
          НИЙТ {selectedCartIds.length} {/*총 ()개의 상품*/}
        </Text>
        <Divider />
        <Flex mt={2} justifyContent={'space-between'} alignItems={'center'}>
          <Text>БҮТЭЭГДЭХҮҮН</Text>
          <MoneyText amount={cost?.product || 0} unit={'₩'} />
        </Flex>
        <Flex
          mt={2}
          mb={16}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text>ХҮРГЭЛТ</Text>
          <MoneyText amount={cost?.delivery || 0} unit={'₩'} />
        </Flex>
        <Flex
          mt={2}
          mb={16}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text>ХӨНГӨЛӨЛТ</Text> {/*할인*/}
          <MoneyText amount={cost?.discount || 0} unit={'₩'} prefix="-" />
        </Flex>
        <Divider />
        <Flex
          mt={2}
          mb={8}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text fontWeight={'bold'}>НИЙТ ҮНЭ</Text> {/*총 결제예정금액*/}
          <MoneyText amount={cost.getTotalCost()} unit={'₩'} />
        </Flex>
      </Flex>
      <Button
        size={'lg'}
        colorScheme="blue"
        mb={2}
        onClick={() =>
          selectedCartIds.length === 0 ? () => {} : onSubmit(true)
        }
      >
        БҮГДИЙГ НЬ ЗАХИАЛАХ
      </Button>{' '}
      {/*전체구매버튼*/}
      <Button
        size={'lg'}
        colorScheme="blue"
        variant={'outline'}
        backgroundColor={'white'}
        onClick={() =>
          selectedCartIds.length === 0 ? () => {} : onSubmit(false)
        }
      >
        СОНГОСНОО ЗАХИАЛАХ
      </Button>{' '}
      {/*선택구매버튼*/}
    </Flex>
  )
}
