import { PlusSquareIcon } from '@chakra-ui/icons'
import {
  Grid,
  GridItem,
  Button,
  Input,
  HStack,
  Select,
  Flex,
  Text,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'
import { useExtractAddressFromImage } from 'common/api/domains/profile/profileMutation'
import { AddressForm, useAddress } from 'common/hooks/useAddress'
import { useImageUpload } from 'common/hooks/useFileUpload'
import { ImageType } from 'common/util/enum'
import { phoneIdentificationNumbers } from 'common/util/phone'
import { useFormikContext } from 'formik'
import React, { ChangeEvent, useEffect, useRef } from 'react'
import { HintText } from 'views/component/HintText'
import { PostcodeModal } from 'views/component/PostcodeModal'
import { OrderFomikValuesType } from 'views/purchase/Order/type'

type ImageAddressProps = {
  handleAddress: (address: AddressForm) => void
  isActive: boolean
}

export const ImageAddress = ({
  handleAddress,
  isActive,
}: ImageAddressProps) => {
  const { errors } = useFormikContext<OrderFomikValuesType>()
  const {
    selectImageHandler,
    uploadImageHandler,
    previewUrl,
    image,
    isLoading: uploadLoading,
  } = useImageUpload()
  const {
    data,
    mutate,
    isLoading: getAddressLoading,
  } = useExtractAddressFromImage()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const photoAddress = useAddress()

  const onHandleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = selectImageHandler(event)
    if (!file) return
    await uploadImageHandler(file, ImageType.ADDRESS)
    fileInputRef.current!.value = ''
  }

  useEffect(() => {
    if (!image) return
    mutate(image.id)
  }, [image?.id])

  useEffect(() => {
    if (!data) return
    photoAddress.setAddress({ address1: data })
    onOpen()
  }, [data])

  useEffect(() => {
    if (!isActive) return
    handleAddress(photoAddress.address)
  }, [
    isActive,
    photoAddress.address.name,
    photoAddress.address.postcode,
    photoAddress.address.address1,
    photoAddress.address.address2,
    photoAddress.address.phone,
  ])

  if (previewUrl) {
    return (
      <Grid templateColumns={'84px 1fr'} rowGap={4} mb={8}>
        <GridItem pt={2} fontSize={14} color={'#505050'}>
          **ХҮЛЭЭН АВАГЧ
        </GridItem>{' '}
        {/*수령인*/}
        <GridItem>
          <Input
            size={'sm'}
            value={photoAddress.address.name}
            onChange={(e) => photoAddress.onChangeName(e.target.value)}
          />
          <HintText
            hintType={'error'}
            hint={errors.address?.name}
            fontSize="xs"
          />
        </GridItem>
        <GridItem pt={2} fontSize={14} color={'#505050'}>
          *ХАЯГ
        </GridItem>{' '}
        {/*주소*/}
        <GridItem>
          <Flex flexDirection={'column'}>
            <Button
              bg="#606060"
              color={'white'}
              _disabled={{ bg: '#606060' }}
              _hover={{ bg: '#909090' }}
              isDisabled={uploadLoading || getAddressLoading}
              onClick={() => fileInputRef.current?.click()}
              mb={4}
              size={'sm'}
            >
              {uploadLoading || getAddressLoading ? (
                <Spinner />
              ) : (
                'ЗУРАГ ДАХИЖ ОРУУЛАХ'
              )}
            </Button>{' '}
            {/*사진재업로드*/}
            <input
              ref={fileInputRef}
              type="file"
              onChange={onHandleUpload}
              style={{ display: 'none' }}
            />
            <PostcodeModal
              isOpen={isOpen}
              defaultQuery={data ?? ''}
              onClose={onClose}
              onComplete={(address) => {
                photoAddress.onChangePostcode(address.zonecode)
                photoAddress.onChangeAddress1(address.address)
                onClose()
              }}
            />
            <Input
              placeholder="БҮСИЙН КОД"
              mr={2}
              value={photoAddress.address.postcode}
              size={'sm'}
              onChange={(e) => photoAddress.onChangePostcode(e.target.value)}
            />{' '}
            {/*우편번호*/}
            <HintText
              hintType={'error'}
              hint={errors.address?.postcode}
              fontSize="xs"
            />
            <Input
              value={photoAddress.address.address1}
              onChange={(e) => photoAddress.onChangeAddress1(e.target.value)}
              placeholder="ХАЯГ"
              size={'sm'}
            />{' '}
            {/*기본주소*/}
            <HintText
              hintType={'error'}
              hint={errors.address?.address1}
              fontSize="xs"
            />
            <Input
              value={photoAddress.address.address2}
              onChange={(e) => photoAddress.onChangeAddress2(e.target.value)}
              placeholder="ҮЛДСЭН ХАЯГ"
              size={'sm'}
            />{' '}
            {/*나머지주소*/}
            <HintText
              hintType={'error'}
              hint={errors.address?.address2}
              fontSize="xs"
            />
          </Flex>
        </GridItem>
        <GridItem pt={2} fontSize={14} color={'#505050'}>
          *УТАС
        </GridItem>{' '}
        {/*휴대전화*/}
        <GridItem>
          <HStack>
            <Select
              value={photoAddress.address.phone.split('-')[0]}
              onChange={(e) => photoAddress.onChangePhone(e.target.value, 0)}
              placeholder={'---'}
              size={'sm'}
              w={'72px'}
            >
              {phoneIdentificationNumbers.map((v, index) => (
                <option key={index} value={v}>
                  {v}
                </option>
              ))}
            </Select>
            <Text>-</Text>
            <Input
              value={photoAddress.address.phone.split('-')[1]}
              onChange={(e) => photoAddress.onChangePhone(e.target.value, 1)}
              type="number"
              size={'sm'}
              w={'65px'}
            />
            <Text>-</Text>
            <Input
              value={photoAddress.address.phone.split('-')[2]}
              onChange={(e) => photoAddress.onChangePhone(e.target.value, 2)}
              type="number"
              size={'sm'}
              w={'65px'}
            />
          </HStack>
          <HintText
            hintType={'error'}
            hint={errors.address?.phone}
            fontSize="xs"
          />
        </GridItem>
      </Grid>
    )
  }
  return (
    <Flex
      cursor={'pointer'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      p={4}
      border={'1px dashed gray'}
      onClick={() => fileInputRef.current?.click()}
    >
      <PlusSquareIcon fontSize={'2xl'} />
      <Text fontWeight={'bold'}>ДАРААД ЗУРГАА ОРУУЛНА УУ.</Text>
      {/*클릭해서 사진을 업로드해 주세요.*/}
      <input
        ref={fileInputRef}
        type="file"
        onChange={onHandleUpload}
        style={{ display: 'none' }}
      />
    </Flex>
  )
}
