import { useUploadImage } from 'common/api/domains/image/imageMutation'
import { ImageType } from 'common/util/enum'
import { ImageModel } from 'domain/image/model'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { MutateOptions } from 'react-query'

export const useImageUpload = () => {
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    null,
  )
  const [imageModel, setImageModel] = useState<ImageModel | null>(null)
  const { mutate, data, isLoading } = useUploadImage()

  const selectImageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    if (!file) return
    createImagePreviewUrl(file)
    return file
  }

  const uploadImageHandler = async (
    file: File,
    type: ImageType,
    options?: MutateOptions<ImageModel, Error, FormData, unknown>,
  ) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    try {
      await mutate(formData, options)
    } catch (error) {
      console.error('Error:', error)
      alert('파일 업로드 중 오류 발생.')
    }
  }

  const createImagePreviewUrl = (file: File) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  }

  useEffect(() => {
    if (isLoading) return
    if (!data) return
    setImageModel({ ...data })
  }, [isLoading])

  return {
    selectImageHandler,
    uploadImageHandler,
    previewUrl,
    isLoading,
    imageModel,
    image: data,
  }
}
