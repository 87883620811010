import { useQuery } from 'react-query'
import {
  fetchCart,
  fetchCartIds,
  fetchCartIdsByProductId,
  fetchEstimatedCost,
  fetchOrder,
} from '../../fetcher/purchase/queries'
import { CostRequestDTO } from 'domain/purchase/request.dto'

export default function purchaseQuery() {
  // todo
}

export const useGetCartIds = () => useQuery(['carts'], () => fetchCartIds())
export const useGetCartsByProductId = (productId: number) =>
  useQuery(['carts', 'products', productId], () =>
    fetchCartIdsByProductId(productId),
  )
export const useGetCart = (cartId: number) =>
  useQuery(['carts', cartId], () => fetchCart(cartId), { cacheTime: 0 })
export const useGetCost = (costRequestDto: CostRequestDTO) =>
  useQuery(
    [
      'carts',
      'cost',
      costRequestDto.products.map((p) => p.productId).join('-'),
    ],
    () => fetchEstimatedCost(costRequestDto),
    { cacheTime: 0 },
  )
export const useGetOrder = (orderId: number) =>
  useQuery(['order', orderId], () => fetchOrder(orderId), { enabled: false })
