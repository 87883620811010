import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { UploadReceipt } from './UploadReceipt'
import { ReuploadReceipt } from './ReouploadReceipt'
import { UploadedReceipt } from './UploadedReceipt'
import { OrderStatus } from 'common/util/enum'
import { useNavigate, useParams } from 'react-router-dom'
import { MoneyText } from 'views/component/MoneyText'
import { useGetOrder } from 'common/api/domains/purchase/purchaseQuery'

export const OrderReceiptDesktop = () => {
  const { orderId } = useParams()
  const { data: order, refetch } = useGetOrder(Number(orderId))
  const navigate = useNavigate()

  useEffect(() => {
    console.log('order', order?.productCost)
    if (!orderId || isNaN(Number(orderId))) return
    refetch()
  }, [orderId])

  if (!order) return null

  return (
    <VStack w={'1200px'}>
      <Text fontSize={'xl'} fontWeight={'bold'} mt={12} mb={4}>
        ЗАХИАЛГА ДУУССАН
      </Text>
      {/*주문완료*/}
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        w="5xl"
        pt={12}
        pb={12}
        pl={64}
        pr={64}
        backgroundColor={'#F4F4F4'}
      >
        <CheckCircleIcon fontSize={48} color={'#005E9E'} />
        <Text mt={4} fontSize={'lg'} fontWeight={'bold'}>
          ТАНЫ ЗАХИАЛГА АМЖИЛТТАЙ ДУУСЛАА.
        </Text>{' '}
        {/*결제가 완료되었습니다.*/}
        <Text mb={4}>ЗАХИАЛГА NO. [{order.orderNumber}]</Text> {/*주문번호*/}
        <Divider />
        <Flex
          mt={4}
          mb={4}
          w={'100%'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text>НИЙТ ТӨЛБӨР</Text> {/*최종 결제 금액*/}
          <MoneyText
            amount={order.productCost + order.deliveryCost - order.discount}
            unit={'₩'}
            textProps={{
              fontWeight: 'bold',
              fontSize: 'lg',
              color: '#005E9E',
            }}
          />
        </Flex>
        <Flex w={'100%'} justifyContent={'space-between'}>
          <Text>ЗАХИАЛГА ЭЗЭМШИГЧИЙН НЭР</Text> {/*입금자명*/}
          <Text color={'#606060'}>{order.depositorName}</Text>
        </Flex>
        <Flex w={'100%'} justifyContent={'space-between'}>
          <Text mb={4}>ТӨЛБӨР ИЛГЭЭХ ДАНС</Text> {/*입금은행*/}
          <Text color={'#606060'} mb={4}>
            {`${order.accountBankName} ${order.accountNumber} (${order.accountHolder})`}
          </Text>
        </Flex>
        {/* {order?.status === OrderStatus.PENDING && (
          <UploadReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )}
        {order?.status === OrderStatus.CHECKING && (
          <ReuploadReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )}
        {order?.status === OrderStatus.APPROVED && (
          <UploadedReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )}
        {order?.status === OrderStatus.REJECTED && (
          <UploadReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )}
        {order?.status === OrderStatus.CANCELED && (
          <UploadReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )} */}
      </Flex>
      <Flex flexDirection={'column'} w={'5xl'}>
        <Text mt={4} mb={2} fontSize={'lg'} fontWeight={'bold'}>
          ХҮРГЭЛТ
        </Text>
        {/*배송정보*/}
        <Divider borderWidth={2} borderColor={'gray'} />
        <Grid templateColumns={'128px 1fr'} gap={4} mt={8} mb={8}>
          <GridItem color={'#505050'}>ХҮЛЭЭН АВАГЧ</GridItem> {/*수령인*/}
          <GridItem>{order.name}</GridItem>
          <GridItem color={'#505050'}>ХАЯГ</GridItem> {/*주소*/}
          <GridItem>{`[${order.postcode}] ${order.address1} ${order.address2}`}</GridItem>
          <GridItem color={'#505050'}>УТАС</GridItem> {/*연락처*/}
          <GridItem>{order.phone}</GridItem>
        </Grid>
        <Divider borderWidth={2} borderColor={'gray'} />
        <Text mt={8} mb={2} fontSize={'lg'} fontWeight={'bold'}>
          ЗАХИАЛСАН БҮТЭЭГДЭХҮҮН
        </Text>{' '}
        {/*주문상품*/}
        <Divider borderWidth={2} borderColor={'gray'} />
        {(order.orderProducts || []).map((orderProduct) => {
          return (
            <Grid
              key={orderProduct.id}
              templateColumns={'128px 1fr'}
              gap={4}
              mt={8}
              mb={8}
            >
              <GridItem color={'#505050'}>{orderProduct.productName}</GridItem>
              <GridItem>
                <MoneyText
                  amount={orderProduct.price * orderProduct.count}
                  unit={'₩'}
                  suffix={`(${orderProduct.count} ${orderProduct.unit})`}
                />
              </GridItem>
            </Grid>
          )
        })}
        <Divider borderWidth={2} borderColor={'gray'} />
        <Text mt={8} mb={2} fontSize={'lg'} fontWeight={'bold'}>
          ТӨЛБӨРИЙН МЭДЭЭЛЭЛ
        </Text>{' '}
        {/*결제 정보*/}
        <Divider borderWidth={2} borderColor={'gray'} />
        <Grid gap={4} mt={8} mb={8}>
          <GridItem
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text>ЗАХИАЛСАН БҮТЭЭГДЭХҮҮН</Text> {/*주문상품*/}
            <MoneyText amount={order.productCost} unit={'₩'} />
          </GridItem>
          <GridItem
            gap={4}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text>ХҮРГЭЛТ</Text> {/*배송비*/}
            <MoneyText amount={order.deliveryCost} unit={'₩'} />
          </GridItem>
          <GridItem
            gap={4}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text>ХӨНГӨЛӨЛТ</Text> {/*할인*/}
            <MoneyText amount={order.discount} unit={'₩'} prefix="-" />
          </GridItem>
        </Grid>
        <Divider borderColor={'gray'} />
        <Flex justifyContent={'space-between'} mt={4} mb={16}>
          <Text fontSize={'lg'} fontWeight={'bold'}>
            НИЙТ ТӨЛБӨР
          </Text>
          {/*최종 결제금액*/}
          <MoneyText
            amount={order.productCost + order.deliveryCost - order.discount}
            unit={'₩'}
            textProps={{ fontSize: 'lg', fontWeight: 'bold' }}
          />
        </Flex>
      </Flex>
      <Button
        w={'368px'}
        onClick={() => navigate('/')}
        size={'lg'}
        colorScheme="blue"
        mb={32}
      >
        ОЙЛГОЛОО
      </Button>
    </VStack>
  )
}
