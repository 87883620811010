import { Box, Heading, Text } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

export function SignOutHeader() {
  const isMobile = useRecoilValue(clientIsMobile)
  return (
    <>
      <Heading mb={1} fontSize={20} textAlign={'center'}>
        БҮРТГЭЛ ЦУЦЛАХ {/*회원탈퇴*/}
      </Heading>
      <Box px={10} py={5} textAlign={'center'}>
        <Text as="sub" fontSize={12} textAlign={'center'}>
          ХЭРЭВ ТА ГИШҮҮНЧЛЭЛЭЭ ЦУЦАЛБАЛ ҮЙЛЧИЛГЭЭГ АШИГЛАХ БОЛОМЖГҮЙ БОЛНО.{' '}
          {/*회원 탈퇴 시 서비스 이용이 불가합니다.*/}
        </Text>
      </Box>
      <Box w={isMobile ? '95%' : '50%'} textAlign={'center'}>
        <Text as="b" fontSize={11}>
          {' '}
          ДООРХ УРЬДЧИЛАН СЭРГИЙЛЭХ АРГА ХЭМЖЭЭГ УНШИНА УУ.
          {/*아래 유의사항을 꼭 읽어주세요.*/}
        </Text>
      </Box>
    </>
  )
}
