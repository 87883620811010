import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  LinkBox,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { useDeleteAddressQuery } from 'common/api/domains/profile/profileQuery'
import { customAlert } from 'common/util/string-utils'
import React, { ReactNode, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
// import { isLoginState } from 'state/auth/atoms'
import { checkAddressState } from 'state/profile/atoms'
import Swal from 'sweetalert2'
import { LogoutButton } from 'views/auth/component/LogoutButton'

type ProfileLayout = {
  children: ReactNode
  pageName: string
}
export function ProfileLayout({ children, pageName }: ProfileLayout) {
  const isMobile = useRecoilValue(clientIsMobile)
  const location = useLocation()
  const [activeLink, setActiveLink] = useState('/management/profile')

  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location.pathname])
  const linkStyle = (path: string) => ({
    display: 'flex',
    fontWeight: activeLink === path ? 'bold' : 'normal',
    fontSize: activeLink === path ? '20px' : '16px',
  })
  const navigate = useNavigate()
  const checkedAddress = useRecoilValue(checkAddressState)
  const { mutate, isLoading, isError, isSuccess, error } =
    useDeleteAddressQuery()
  const deleteAddress = () => {
    let defaultAddresId = localStorage.getItem('default-address')
    if (checkedAddress.length === 0) {
      return
    }
    if (defaultAddresId) {
      for (let i = 0; i < checkedAddress.length; i++) {
        if (checkedAddress[i] == Number(defaultAddresId)) {
          customAlert('Өгөгдмөл хүргэлтийн хаягийг устгах боломжгүй.')
          // alert('Өгөгдмөл хүргэлтийн хаягийг устгах боломжгүй.')
          return
        }
        {
          /*기본 배송지 주소는 삭제할 수 없습니다.*/
        }
      }
    }
    mutate(checkedAddress ? checkedAddress : [], {
      onSuccess: (data) => {
        navigate(0)
      },
      onError: (error) => {},
      onSettled: (data, error) => {},
    })
  }

  return (
    <Box w={isMobile ? '375px' : '1200px'}>
      <Grid templateColumns={isMobile ? '1fr 4fr 1fr' : '1fr 2fr'} mb={4}>
        <GridItem display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {isMobile ? (
            <Button
              bgColor={'white'}
              onClick={() => navigate(-1)}
              display={'flex'}
            >
              {' '}
              <ArrowBackIcon />
            </Button>
          ) : (
            ''
          )}
        </GridItem>
        <GridItem display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          {!isMobile ? (
            <Box textAlign={'right'}>
              <Link to="/">HOME</Link>&nbsp;/&nbsp;
              <Box style={{ display: 'inline-block', fontWeight: 600 }}>
                {pageName}
              </Box>
            </Box>
          ) : (
            ''
          )}
          <Flex justifyContent={isMobile ? 'center' : 'start'}>
            {isMobile || activeLink !== '/management/profile/modify' ? (
              isMobile ? (
                <Text fontSize="xl" fontWeight={'bold'}>
                  {pageName}
                </Text>
              ) : (
                <Text fontSize="xl" pl={6} fontWeight={'bold'}>
                  {pageName}
                </Text>
              )
            ) : (
              <>
                <Box w={115}></Box>
                <Text fontSize="xl" fontWeight={'bold'}>
                  {pageName}
                </Text>
              </>
            )}
            {!isMobile ? (
              <>
                {location.pathname === '/management/profile/delivery' ? (
                  <Box alignContent={'center'}>
                    <Button
                      colorScheme="blue"
                      size={'sm'}
                      variant={'outline'}
                      borderRadius={4}
                      mr={0}
                      mt={6}
                      width={120}
                      onClick={deleteAddress}
                    >
                      УСТГАХ
                    </Button>{' '}
                    {/*삭제*/}
                    <Button
                      colorScheme="blue"
                      borderRadius={4}
                      width={120}
                      mr={0}
                      mt={6}
                      size={'sm'}
                    >
                      <Link
                        to="/management/profile/delivery/register"
                        // css={{
                        //   textDecoration: 'none',
                        //   '&:hover': {
                        //     textDecoration: 'none',
                        //   },
                        // }}
                      >
                        ХАЯГ НЭМЭХ
                      </Link>{' '}
                      {/*주소 등록*/}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Flex>
        </GridItem>
        {isMobile ? <GridItem /> : null}
      </Grid>
      {isMobile ? (
        <>
          <Divider mb={6} />
          {location.pathname === '/management/profile/delivery' ? (
            <Box
              textAlign={'right'}
              pb={6}
              mt={4}
              borderBottom={'1px solid #000000'}
            >
              <Button
                colorScheme="blue"
                size={'sm'}
                mr={2}
                variant={'outline'}
                borderRadius={4}
                width={100}
                onClick={deleteAddress}
              >
                УСТГАХ
              </Button>{' '}
              {/*삭제*/}
              <Button
                colorScheme="blue"
                borderRadius={4}
                size={'sm'}
                width={120}
                mr={0}
              >
                <Link
                  to="/management/profile/delivery/register"
                  // css={{
                  //   textDecoration: 'none',
                  //   '&:hover': {
                  //     textDecoration: 'none',
                  //   },
                  // }}
                >
                  ХАЯГ НЭМЭХ
                </Link>{' '}
                {/*주소 등록*/}
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {!isMobile && activeLink !== '/management/profile/modify' ? (
        <Grid templateColumns={'1fr 2fr'} gap={6}>
          <GridItem w="100%">
            <Box
              className="gray-bg"
              h={'300px'}
              mx={10}
              p={7}
              borderRadius={10}
              backgroundColor={'#f7f7f7'}
            >
              <Link
                to="/management/profile"
                style={linkStyle('/management/profile')}
              >
                МЭДЭЭЛЭЛ
              </Link>
              {/*나의 쇼핑 정보*/}
              <Divider borderColor="gray.400" my={3} />
              <Link
                to="/management/profile/order-list"
                style={linkStyle('/management/profile/order-list')}
              >
                ЗАХИАЛГА
              </Link>
              {/*주문내역 조회*/}
              <Link
                to="/management/profile/delivery"
                style={linkStyle('/management/profile/delivery')}
              >
                ХҮРГЭЛТИЙН ХАЯГ
              </Link>{' '}
              {/*배송지 관리*/}
              <Divider borderColor="gray.400" my={3} />
              <Link
                to="/management/profile/modify"
                style={linkStyle('/management/profile/modify')}
              >
                МЭДЭЭЛЭЛ ЗАСАХ
              </Link>
              {/*회원 정보 수정*/}
              <LogoutButton />
            </Box>
          </GridItem>
          <GridItem w="100%" mb={5}>
            {children}
          </GridItem>
        </Grid>
      ) : (
        <Box pb={5}>
          <>{children}</>
        </Box>
      )}
    </Box>
  )
}
