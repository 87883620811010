import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
} from '@chakra-ui/react'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { ModalComponentProps } from 'common/util/type'
import React from 'react'

type AddBasketModalProps = {} & ModalComponentProps

export const AddBasketModal = ({ isOpen, onClose }: AddBasketModalProps) => {
  const { goTo: goToBasket } = usePrivateNavigate('/purchase/basket')

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={'90%'}>
        <ModalHeader />
        <ModalBody p={4}>
          <Text textAlign={'center'}>БҮТЭЭГДЭХҮҮН ТАНЫ САГСАНД НЭМЭГДЛЭЭ.</Text>{' '}
          {/*상품이 장바구니에 담겼습니다.*/}
        </ModalBody>
        <ModalFooter
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Flex>
            <Button onClick={onClose} p={6} mr={6} fontSize={16}>
              ХААХ
            </Button>{' '}
            {/*쇼핑마저하기*/}
            <Button
              colorScheme="blue"
              onClick={() => goToBasket()}
              p={6}
              fontSize={16}
            >
              САГС ҮЗЭХ
            </Button>{' '}
            {/*장바구니로 이동*/}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
