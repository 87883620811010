import { UserModel } from 'domain/user/models'
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export type AuthType = {
  jwtToken: string
  refreshToken: string
  loginAt: Date
}

export const authState = atom<AuthType | null>({
  key: 'authState',
  default: null,
})

export const userState = atom<UserModel | null>({
  key: 'userState',
  default: null,
})

// export const refreshTokenState = atom<string>({
//   key: 'refreshTokenState',
//   default: '',
// })

// export const accessTokenState = atom<string>({
//   key: 'accessTokenState',
//   default: '',
// })

// export const isLoginState = atom<boolean>({
//   key: 'isLogin',
//   default: false,
// })

// export const userNameState = atom<string>({
//   key: 'userName',
//   default: '',
// })

// export const userPhoneState = atom<string>({
//   key: 'userPhoneNumber',
//   default: '',
// })
