import {
  Button,
  FormControl,
  HStack,
  Input,
  Table,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { ProfileLayout } from './ProfileLayout'
import { useEffect, useState } from 'react'
import { Address } from 'react-daum-postcode'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAddOrModifyAddressQuery } from 'common/api/domains/profile/profileQuery'
import { UserAddressProps } from 'domain/profile'
import { PostcodeModal } from 'views/component/PostcodeModal'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

export function DeliveryAddressRegister() {
  const isMobile = useRecoilValue(clientIsMobile)
  const location = useLocation()
  const { mutate, isLoading, isError, isSuccess, error } =
    useAddOrModifyAddressQuery()
  const modifyData: UserAddressProps = location.state
  const navigate = useNavigate()
  const [firstInput, setFirstInput] = useState('')
  const [secondInput, setSecondInput] = useState('')
  const [thirdInput, setThirdInput] = useState('')

  const [userName, setUserName] = useState('')
  const [address, setAddress] = useState('')
  const [detailAddress, setDetailAddress] = useState('')
  const [postcode, setPostcode] = useState('')

  useEffect(() => {
    if (
      modifyData &&
      modifyData.address1 &&
      modifyData.name &&
      modifyData.address2 &&
      modifyData.postcode &&
      modifyData.phone
    ) {
      setAddress(modifyData.address1)
      setUserName(modifyData.name)
      setDetailAddress(modifyData.address2)
      setPostcode(modifyData.postcode)
      setFirstInput(modifyData.phone.slice(0, 3))
      setSecondInput(modifyData.phone.slice(4, 8))
      setThirdInput(modifyData.phone.slice(9, 13))
    }
  }, [])

  const submitForm = () => {
    let addreses: UserAddressProps = {
      name: userName,
      postcode: postcode,
      address1: address,
      address2: detailAddress,
      phone: firstInput + '-' + secondInput + '-' + thirdInput,
    }
    if (modifyData) {
      addreses.id = modifyData.id
    }
    mutate(addreses, {
      onSuccess: (data) => {
        navigate('/management/profile/delivery')
      },
      onError: (error) => {},
      onSettled: (data, error) => {},
    })
  }
  const [isOpen, setIsOpen] = useState(false)

  const onToggleModal = () => {
    setIsOpen((prev) => !prev)
  }

  const handleComplete = (data: Address) => {
    setPostcode(data.zonecode)
    setAddress(data.address)
    onToggleModal() // 주소창은 자동으로 사라지므로 모달만 꺼주면 된다.
  }
  return (
    <ProfileLayout pageName="ХАЯГ НЭМЭХ">
      {' '}
      {/*배송지 추가*/}
      <FormControl alignItems="center">
        <Table
          variant="simple"
          fontSize={isMobile ? '11px' : ''}
          color={'#000000'}
          fontWeight={600}
          borderRadius={4}
        >
          <Tr borderTop={'1px solid #E2E8F0'} borderRadius={4}>
            <Td id={'prop'} p={isMobile ? 0 : ''} textAlign={'center'}>
              ХҮЛЭЭН АВАГЧ
            </Td>{' '}
            {/*수령인*/}
            <Td p={isMobile ? 2 : ''}>
              <Input
                value={userName}
                fontSize={isMobile ? '11px' : ''}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Td>
          </Tr>
          <Tr>
            <Td id={'prop'} p={isMobile ? 0 : ''} textAlign={'center'}>
              БҮСИЙН No.
            </Td>{' '}
            {/*우편번호*/}
            <Td p={isMobile ? 2 : ''}>
              <Input
                value={postcode}
                fontSize={isMobile ? '11px' : ''}
                onChange={(e) => setPostcode(e.target.value)}
              />
            </Td>
          </Tr>
          <Tr>
            <Td id={'prop'} p={isMobile ? 0 : ''} textAlign={'center'}>
              ХАЯГ
            </Td>{' '}
            {/*주소*/}
            <Td p={isMobile ? 2 : ''}>
              <HStack>
                <Input
                  value={address}
                  fontSize={isMobile ? '11px' : ''}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <Button
                  bg={'black'}
                  color={'white'}
                  onClick={onToggleModal}
                  fontSize={isMobile ? '11px' : ''}
                  bgColor={'#606060'}
                >
                  ХАЙХ
                </Button>{' '}
                {/*검색*/}
              </HStack>
              <HStack mt={5} w={'50%'}>
                <Input
                  value={detailAddress}
                  fontSize={isMobile ? '11px' : ''}
                  onChange={(e) => setDetailAddress(e.target.value)}
                />
              </HStack>
            </Td>
          </Tr>

          <Tr>
            <Td
              w={'20%'}
              id={'prop'}
              p={isMobile ? 0 : ''}
              textAlign={'center'}
            >
              УТАС
            </Td>{' '}
            {/*전화번호*/}
            <Td w={'80%'} p={isMobile ? 2 : ''}>
              <HStack spacing={2}>
                <Input
                  value={firstInput}
                  maxLength={3}
                  type="tel"
                  fontSize={isMobile ? '11px' : ''}
                  onChange={(e) => setFirstInput(e.target.value)}
                />
                <Text>-</Text>
                <Input
                  value={secondInput}
                  maxLength={4}
                  type="tel"
                  fontSize={isMobile ? '11px' : ''}
                  onChange={(e) => setSecondInput(e.target.value)}
                />
                <Text>-</Text>
                <Input
                  value={thirdInput}
                  maxLength={4}
                  type="tel"
                  fontSize={isMobile ? '11px' : ''}
                  onChange={(e) => setThirdInput(e.target.value)}
                />
              </HStack>
            </Td>
          </Tr>
        </Table>
        <div style={{ textAlign: 'right', width: '100%', marginTop: '30px' }}>
          <Button
            colorScheme="blue"
            variant="outline"
            mr={3}
            w={'30%'}
            onClick={() => navigate(-1)}
          >
            ХААХ
          </Button>
          <Button colorScheme="blue" w={'30%'} onClick={submitForm}>
            {modifyData ? 'ЗАСАХ' : 'НЭМЭХ'} {/*수정/추가*/}
          </Button>
        </div>
      </FormControl>
      <PostcodeModal
        isOpen={isOpen}
        onClose={onToggleModal}
        onComplete={handleComplete}
      />
    </ProfileLayout>
  )
}
