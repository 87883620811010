import { Flex, Button, Box, Text } from '@chakra-ui/react'
import { getCommonCodeQuery } from 'common/api/domains/goods/goodsQuery'
import { useAuth } from 'common/hooks/useAuth'
import { count } from 'console'
import { ProductModel } from 'domain/goods/model'
import { CreateCartRequestDTO } from 'domain/purchase/request.dto'
import { useFormikContext } from 'formik'
import React from 'react'
import { CountInput } from 'views/component/CountInput'
import { MoneyText } from 'views/component/MoneyText'

export const DeliveryInfo = () => {
  const { data: common } = getCommonCodeQuery()

  return (
    <Box p={2}>
      <Text>
        <Text as="b" fontSize={'md'}>
          결제정보{' '}
        </Text>{' '}
        <br />
        <br />
        <Text fontSize={'sm'}>
          {' '}
          무통장 입금은 상품 구매 대금은 PC뱅킹, 인터넷뱅킹, 텔레뱅킹 혹은
          가까운 은행에서 직접 입금하시면 됩니다.
        </Text>
        <br />
        <Text fontSize={'sm'}>
           주문시 입력한 입금자명과 실제입금자의 성명이 반드시 일치하여야 하며,
          1일 이내로 입금을 하셔야 하며 입금되지 않은 주문은 자동취소 됩니다.
        </Text>
        <br />
        <Text as="b" fontSize={'md'}>
          배송정보{' '}
        </Text>{' '}
        <br />
        <br />
        <Text>- 배송 방법 : 택배 배송</Text>
        <Text>- 지역 : 전국지역</Text>
        <Text>
          - 배송 비용 : ₩
          {common
            ?.filter((code) => code.common_code === 'delivery_fee')
            .map((code) => code.code_value)}
        </Text>
        <Text>
          - 배송 기간 :{' '}
          {common
            ?.filter((code) => code.common_code === 'delivery_days')
            .map((code) => code.code_value)}
          일
        </Text>
        <Text>
          - 배송 안내 : 산간벽지나 도서지방은 별도의 추가금액을 지불하셔야 하는
          경우가 있습니다.고객님께서 주문하신 상품은 입금 확인후 배송해
          드립니다. 다만, 상품종류에 따라서 상품의 배송이 다소 지연될 수
          있습니다.
        </Text>
        <br />
        {/*<Text as="b" fontSize={'xl'}>
          교환정보{' '}
        </Text>{' '}
        <br />
        <br />
        <Text>교환 및 반품 주소</Text>
        <Text>- [02240] 서울 중랑구 사가정로46길 81 1층</Text>
        <br />
        <Text>교환 및 반품이 가능한 경우</Text>
        <Text>
          - 계약내용에 관한 서면을 받은 날부터 7일. 단, 그 서면을 받은 때보다
          재화등의 공급이 늦게 이루어진 경우에는 재화등을 공급받거나 재화등의
          공급이 시작된 날부터 7일 이내
        </Text>
        <Text>
          - 공급받으신 상품 및 용역의 내용이 표시.광고 내용과 다르거나
          계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날
          부터 3월이내, 그사실을 알게 된 날 또는 알 수 있었던 날부터 30일이내
        </Text>
        <br />
        <Text>교환 및 반품이 불가능한 경우</Text>
        <Text>
          - 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된
          경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는
          청약철회를 할 수 있습니다)
        </Text>
        <Text>
          - 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한
          경우
        </Text>
        <Text>
          - 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히
          감소한 경우
        </Text>
        <Text>- 복제가 가능한 재화등의 포장을 훼손한 경우</Text>
        <Text>
          - 개별 주문 생산되는 재화 등 청약철회시 판매자에게 회복할 수 없는
          피해가 예상되어 소비자의 사전 동의를 얻은 경우
        </Text>
        <br />
        <Text></Text>※ 고객님의 마음이 바뀌어 교환, 반품을 하실 경우 상품반송
        비용은 고객님께서 부담하셔야 합니다. (색상 교환, 사이즈 교환 등 포함)*/}
      </Text>
    </Box>
  )
}
