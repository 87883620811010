import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  HStack,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useRecentShoppingInfoQuery } from 'common/api/domains/profile/profileQuery'
import { useGetOrder } from 'common/api/domains/purchase/purchaseQuery'
import { OrderStatus, OrderStatusKorean } from 'common/util/enum'
import { formatDateString } from 'common/util/string-utils'
import { OrderModel } from 'domain/purchase/models'
import { useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { AuthLayout } from 'views/auth'
import { ForgotPasswordHeader } from 'views/auth/component/ForgotPasswordHeader'
import { OrderReceiptBase } from 'views/component/OrderReceiptBase'
import './css/RecentOrderInfo.scss'
export function RecentOrderInfo() {
  const isMobile = useRecoilValue(clientIsMobile)

  const [activeButton, setActiveButton] = useState<string>('findId')
  const [submitFindPassword, setSubmitFindPassword] = useState<boolean>(false)
  const { data: recentlyShoppingInfo } = useRecentShoppingInfoQuery()
  const dynamicSize = {
    attTitle: isMobile ? 'md' : '2xl',
    attCount: isMobile ? 'md' : '3xl',
    width: isMobile ? '40px' : '80px',
    gap: isMobile ? 0 : 5,
    iconSize: isMobile ? 8 : 10,
    tableSize: isMobile ? 'xs' : 'sm',
  }

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    nextRef: React.RefObject<HTMLInputElement>,
  ) => {
    if (e.target.value.length === e.target.maxLength) {
      nextRef.current?.focus()
    }
  }
  const submit = () => {
    if (activeButton !== 'findId' && !submitFindPassword) {
      setSubmitFindPassword(true)
    } else if (activeButton !== 'findId' && submitFindPassword) {
    } else {
    }
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement
    if (target.id === 'findPassword') {
      setActiveButton('findPassword')
    } else {
      setActiveButton('findId')
    }
    if (submitFindPassword) {
      setSubmitFindPassword(false)
    }
  }
  return (
    <Box my={5}>
      <Text as="b">ЗАХИАЛГА МЭДЭЭЛЭЛ</Text>
      {/*최근 주문 정보*/}
      <Divider borderColor="gray.400" mt={3} />
      <TableContainer>
        <Table size={dynamicSize.tableSize}>
          <Thead textAlign={'center'}>
            <Tr className="order-list-header">
              <Th>ӨДӨР[No.]</Th>
              {/*주문일자[주문번호]*/}
              <Th>БҮТЭЭГДЭХҮҮН</Th> {/*상품명*/}
              <Th>ҮНЭ / Ш</Th> {/*가격/수량*/}
              <Th>НИЙТ ҮНЭ / Ш</Th> {/*총금액/수량*/}
              <Th textAlign={'center'}>НӨХЦӨЛ</Th> {/*주문상태*/}
            </Tr>
          </Thead>
          <Tbody>
            {recentlyShoppingInfo?.orderList?.map(
              (order: OrderModel, index: number) => (
                <Tr key={index}>
                  <Td>
                    <Text>
                      <>
                        {order?.createdAt
                          ? formatDateString(order.createdAt)
                          : ''}
                        (No. {order.id} )
                      </>
                    </Text>
                  </Td>
                  <Td>
                    {order?.orderProducts?.map((op) => (
                      <Text display={'flex'}>{op.productName}</Text>
                    ))}
                  </Td>
                  <Td>
                    {order?.orderProducts?.map((op) => (
                      <Text display={'flex'}>
                        {op.price.toLocaleString('en-us')}
                      </Text>
                    ))}
                  </Td>
                  <Td>
                    <Text>
                      ₩
                      {order?.orderProducts
                        ?.reduce(
                          (totalPrice, op) => totalPrice + (op.price || 0),
                          0,
                        )
                        .toLocaleString('en-us')}
                       (
                      {order?.orderProducts
                        ?.reduce(
                          (totalCount, op) => totalCount + (op.count || 0),
                          0,
                        )
                        .toLocaleString('en-us')}
                      )
                    </Text>
                  </Td>
                  <Td textAlign={'center'}>
                    <Text>
                      {OrderStatusKorean[order?.status ? order?.status : 0]}
                    </Text>
                    {/* <OrderReceiptBase orderId={order.id} /> */}
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
