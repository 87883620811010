import * as bcrypt from 'bcryptjs'
export default function hashingString(value: string) {
  return bcrypt.hashSync(value)
}

export const getRandomValue = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const charactersLength = characters.length

  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}
