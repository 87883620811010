import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Select,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { ProductItem } from './ProductItem'
import { BreadcrumbNavigation } from 'views/component/BreadcrumbNavigation'
import { Link, useParams } from 'react-router-dom'
import {
  useCategoryQuery,
  useProductListQuery,
} from 'common/api/domains/goods/goodsQuery'
import { ProductListSortType } from '../type'

export const ProductListDesktop = () => {
  const { categoryId } = useParams<{ categoryId: string }>()
  const { data: products } = useProductListQuery(categoryId || '1')
  const { data: category } = useCategoryQuery(categoryId || '1')
  const [sortType, setSortType] = useState<ProductListSortType>(
    ProductListSortType.NAME_ASC,
  )
  if (!category) return null

  return (
    <Box w={'1200px'}>
      <Flex justifyContent={'end'}>
        <BreadcrumbNavigation
          items={[
            {
              name: 'HOME',
              breadcrumbLinkProps: { href: '/goods/categories' },
            },
            {
              name: category.name,
              breadcrumbLinkProps: {
                href: `/goods/categories/${categoryId}/products`,
              },
            },
          ]}
        />
      </Flex>
      <Text textAlign={'center'} fontSize={24} fontWeight={'bold'} mb={8}>
        {category.name}
      </Text>
      <Flex justifyContent={'space-between'}>
        <Flex>
          <Text fontWeight={'bold'}>БҮТЭЭГДЭХҮҮН</Text> {/* 검색결과*/}
          <Text color={'#D05A51'} ml={2} fontWeight={'bold'}>
            {products?.length || 0}
          </Text>
          {/*<Text ml={1} fontWeight={'bold'}>
            건
          </Text>   //해당 부분은없어도될것같아요*/}
        </Flex>
        <Select
          w={'inherit'}
          size={'sm'}
          value={sortType}
          onChange={(e) =>
            setSortType(e.target.value as unknown as ProductListSortType)
          }
          variant={'unstyled'}
        >
          <option value={ProductListSortType.NAME_ASC}>А-Б</option>{' '}
          {/*오름차순*/}
          <option value={ProductListSortType.NAME_DESC}>Б-А</option>{' '}
          {/*내림차순*/}
          <option value={ProductListSortType.PRICE_DESC}>ӨНДӨР ҮНЭ</option>{' '}
          {/*가격높은순*/}
          <option value={ProductListSortType.PRICE_ASC}>БАГА ҮНЭ</option>{' '}
          {/*가격낮은순*/}
        </Select>
      </Flex>
      <Divider borderColor={'#606060'} mt={4} mb={12} />
      <Grid
        w={'1200px'}
        templateColumns={'repeat(auto-fill, 272px)'}
        gap={'26px'}
      >
        {products &&
          products
            .sort((a, b) => {
              switch (sortType) {
                case ProductListSortType.NAME_ASC:
                  return a.name.localeCompare(b.name)
                case ProductListSortType.NAME_DESC:
                  return b.name.localeCompare(a.name)
                case ProductListSortType.PRICE_ASC:
                  return a.price - b.price
                case ProductListSortType.PRICE_DESC:
                  return b.price - a.price
                default:
                  return 0
              }
            })
            .map((product, index) => (
              <GridItem key={index}>
                <Link to={`/goods/products/${product.id}`}>
                  <ProductItem product={product} />
                </Link>
              </GridItem>
            ))}
      </Grid>
    </Box>
  )
}
