import React from 'react'
import { OrderDesktop } from './desktop'
import { OrderMobile } from './mobile'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

export const Order = () => {
  const isMobile = useRecoilValue(clientIsMobile)

  if (isMobile) return <OrderMobile />
  return <OrderDesktop />
}
