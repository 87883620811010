import { Route, Routes } from 'react-router-dom'
import {
  DeliveryAddressRegister,
  ProfileInfo,
  DeliveryAddressManagement,
  OrderList,
} from 'views/profile'
import { WaitingDelivery } from 'views/payment'
import { OrderReceipt, OrderDetail } from 'views/purchase'
import { Order } from 'views/purchase/Order'
import { BasketPage } from 'pages/BasketPage'
import { OrderPage } from 'pages/OrderPage'
import { ModifyProfile } from 'views/profile/ModifyProfile'
import { DetailOrderInfo } from 'views/profile/DetailOrderInfo'

export default function PrivateRoutes() {
  return (
    <Routes>
      {/* 사용자, 배송지, 장바구니 */}
      <Route
        path="/management/profile/*"
        element={
          <Routes>
            <Route path="/" element={<ProfileInfo />} />
            <Route
              path="/address/management"
              element={<DeliveryAddressManagement />}
            />
            <Route path="/address/add" element={<DeliveryAddressRegister />} />
          </Routes>
        }
      />
      {/* 영수증, 주문 */}
      <Route
        path="/purchase/*"
        element={
          <Routes>
            <Route path="/basket" element={<BasketPage />} />
            <Route path="/order" element={<OrderPage />} />
            <Route path="/orders" element={<OrderList />} />
            <Route path="/orders/:orderId" element={<OrderDetail />} />
            <Route path="/orders/:orderId/receipt" element={<OrderReceipt />} />
            <Route
              path="/orders/:orderId/waiting-delivery"
              element={<WaitingDelivery />}
            />
          </Routes>
        }
      />
      {/* 결제 */}
      <Route path="/payment/*" element={<Routes></Routes>} />

      {/* 프로필 */}
      <Route
        path="/profile/*"
        element={
          <Routes>
            <Route path="/" element={<ProfileInfo />} />
            <Route path="/delivery" element={<DeliveryAddressManagement />} />
            <Route path="/modify" element={<ModifyProfile />} />
            <Route path="/order-list" element={<OrderList />} />
            <Route
              path="/delivery/register"
              element={<DeliveryAddressRegister />}
            />
            <Route
              path="/delivery/modify"
              element={<DeliveryAddressRegister />}
            />
            <Route path="/order/detail" element={<DetailOrderInfo />} />
          </Routes>
        }
      />
    </Routes>
  )
}
