export enum ImageType {
  PRODUCT = 'product',
  RECEIPT = 'receipt',
  ADDRESS = 'address',
}

export enum UserRole {
  ADMIN = 0,
  SUB_ADMIN = 1,
  CLIENT = 11,
}

export enum OrderStatus {
  PENDING = 1,
  CHECKING = 2,
  APPROVED = 3,
  REJECTED = 4,
  RETURN = 7,
  REFUND = 8,
  CANCELED = 9,
}
// 1: 대기, 2: 배송 준비 중, 3: 배송 중, 4: 배송 완료, 9: 주문 취소
export enum OrderStatusKorean {
  ТӨЛӨГДӨӨГҮЙ = 1,
  БЭЛДЭЖБАЙГАА = 2,
  ХҮРГЭЖБАЙГАА = 3,
  ХҮРГЭГДСЭН = 4,
  반품 = 7,
  환불 = 8,
  ЦУЦЛАГДСАН = 9,
}

export enum DeliveryStatus {
  PENDING = 1,
  PREPARING = 2,
  DELIVERING = 3,
  ARRIVED = 4,
  CANCELED = 9,
}

export enum TransactionStatus {
  COMPLETE = 1,
  REFUND = 9,
}
