export const jsonToQueryString = (json: Record<string, any>): string => {
  return Object.keys(json)
    .map((key) => {
      const value = json[key]
      if (Array.isArray(value)) {
        return value
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join('&')
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')
}
