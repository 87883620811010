import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { MoneyText } from 'views/component/MoneyText'
import { useGetCost } from 'common/api/domains/purchase/purchaseQuery'
import { useRecoilValue } from 'recoil'
import { orderProductFormsState } from 'state/purchase/atoms'
import { CostModel } from 'domain/purchase/models'
import { OrderFomikValuesType } from '../type'
import { CustomModal } from 'views/component/CustomModal'

type OrderAddonProps = {}

export const OrderAddon = ({}: OrderAddonProps) => {
  const { values, submitForm, setFieldValue } =
    useFormikContext<OrderFomikValuesType>()
  const orderProductForms = useRecoilValue(orderProductFormsState)
  const { data, isLoading } = useGetCost({
    products: orderProductForms.map(({ productId, count }) => ({
      productId,
      count,
    })),
  })

  const isDisabled =
    orderProductForms.length === 0 ||
    !values.address.name ||
    !values.address.postcode ||
    !values.address.address1 ||
    !values.address.phone ||
    !values.depositorName

  const deliveryCost = data?.delivery || 0
  const discount = data?.discount || 0
  const productCost = data?.product || 0

  useEffect(() => {
    if (isLoading || !data) return
    setFieldValue('deliveryCost', deliveryCost)
    setFieldValue('discount', discount)
    setFieldValue('productCost', productCost)
  }, [isLoading])

  const [isCustomModalOpen, setCustomModalOpen] = useState(false)

  const handleModalClose = () => {
    setCustomModalOpen(false)
  }

  const handleModalSubmit = () => {
    submitForm()
    setCustomModalOpen(false)
  }

  const handleOrder = () => {
    if (isDisabled) {
      setCustomModalOpen(true)
    } else {
      submitForm()
    }
  }

  return (
    <Flex
      h={'fit-content'}
      flexDirection={'column'}
      mb={4}
      mt={3}
      pl={2}
      pr={2}
    >
      <Text fontWeight={'bold'} fontSize={'lg'} mb={2}>
        ТӨЛБӨРИЙН МЭДЭЭЛЭЛ
      </Text>{' '}
      {/*결제 정보*/}
      <Divider borderColor={'gray'} />
      <Flex mt={2} justifyContent={'space-between'} alignItems={'center'}>
        <Text fontSize={14}>БҮТЭЭГДЭХҮҮН</Text> {/*주문상품*/}
        <MoneyText amount={productCost} unit={'₩'} />
      </Flex>
      <Flex
        mt={2}
        mb={4}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text fontSize={14}>ХҮРГЭЛТ</Text> {/*배송비*/}
        <MoneyText
          amount={deliveryCost}
          unit={'₩'}
          textProps={{ fontSize: 14 }}
        />
      </Flex>
      <Flex
        mt={2}
        mb={4}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text fontSize={14}>ХӨНГӨЛӨЛТ</Text> {/*할인*/}
        <MoneyText
          amount={discount}
          unit={'₩'}
          prefix="-"
          textProps={{ fontSize: 14 }}
        />
      </Flex>
      <Divider />
      <Flex
        mt={4}
        mb={8}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text fontSize={16} fontWeight={'bold'}>
          НИЙТ ТӨЛБӨР
        </Text>{' '}
        {/*최종 결제금액*/}
        <MoneyText
          amount={productCost + deliveryCost - discount}
          unit={'₩'}
          textProps={{ fontSize: 16 }}
        />
      </Flex>
      <Text fontSize={14} mb={4}>
        ЗАХИАЛГЫН МЭДЭЭЛЛИЙГ БАТАЛГААЖУУЛЖ, ЗӨВШӨӨРЧ БАЙНА.
      </Text>{' '}
      {/*주문 내용 확인하였으며, 약관에 동의합니다.*/}
      <Button
        onClick={handleOrder}
        size={'md'}
        colorScheme="blue"
        mb={2}
        h={50}
        borderRadius={20}
      >
        ХУДАЛДАН АВАХfasdfad
      </Button>{' '}
      {/*결제하기*/}
      <CustomModal
        isOpen={isCustomModalOpen}
        onClose={handleModalClose}
        submit={handleModalSubmit}
        message="ЗАХИАЛГЫН МЭДЭЭЛЭЛ ДУТУУ БАЙНА. ТА ШАЛГАНА УУ?" /*주문 정보가 불완전합니다. 확인해 주세요.*/
        submitButtonMessage="ЗА" /*예*/
        closeButtonMessage="ҮГҮЙ" /*아니오*/
      />
    </Flex>
  )
}
