import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { ProfileLayout } from './ProfileLayout'
import { ChevronRightIcon } from '@chakra-ui/icons'
// import { useRecoilState } from 'recoil'
// import { userNameState } from 'state/auth/atoms'
import { useRecentShoppingInfoQuery } from 'common/api/domains/profile/profileQuery'
import { OrderModel } from 'domain/purchase/models'
import { useAuth } from 'common/hooks/useAuth'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { RecentOrderInfo } from './RecentOrderInfo'
import { LogoutButton } from 'views/auth/component/LogoutButton'

export function ProfileInfo() {
  // const [userName, setUserName] = useRecoilState(userNameState)
  const isMobile = useRecoilValue(clientIsMobile)

  const { user } = useAuth()
  const { data: recentlyShoppingInfo } = useRecentShoppingInfoQuery()
  const dynamicSize = {
    attTitle: isMobile ? '11px' : '12px',
    attCount: isMobile ? '18px' : '20px',
    width: isMobile ? '40px' : '80px',
    gap: isMobile ? 0 : 8,
    iconSize: isMobile ? 6 : 10,
    tableSize: isMobile ? 'xs' : 'sm',
    fontsize: isMobile ? '12px' : '24px',
  }
  useEffect(() => {}, [recentlyShoppingInfo])
  return (
    <>
      <ProfileLayout pageName="МИНИЙ МЭДЭЭЛЭЛ">
        {' '}
        {/*마이페이지*/}
        {!isMobile ? (
          <Box
            w={'100%'}
            className="gray-bg"
            h={'65px'}
            alignContent={'center'}
            border={'1px solid black'}
            borderRadius={10}
            bgColor={'white'}
            mb={5}
          >
            <Flex p={5}>
              <Text as="b" fontSize={'18px'}>
                САЙН БАЙНА УУ || ХЭРЭГЛЭГЧ : {user?.name || ''}{' '}
              </Text>
            </Flex>{' '}
            {/*안녕하세요*/}
          </Box>
        ) : (
          ''
        )}
        <Box my={5}>
          <Text as="b">МИНИЙ ЗАХИАЛГЫН ТҮҮХ (СҮҮЛИЙН 3 САР)</Text>
          {/*나의 주문처리 현황(최근 3개월 기준)*/}
          <Divider borderColor="gray.400" my={3} />
          <Flex justifyContent="space-between" alignItems="center">
            <Box
              className="TEST"
              width={isMobile ? '' : 'calc(100% - 100px)'}
              px={isMobile ? 2 : 15}
            >
              <Flex justifyContent={'left'} gap={dynamicSize.gap}>
                <Box>
                  <Box>
                    <Center>
                      <Text fontSize={dynamicSize.attCount} as={'b'}>
                        {recentlyShoppingInfo?.status?.pending
                          ? recentlyShoppingInfo?.status?.pending
                          : 0}
                      </Text>
                    </Center>
                  </Box>
                  <Box verticalAlign={'center'}>
                    <Text fontSize={dynamicSize.attTitle} textAlign={'center'}>
                      ТӨЛӨГ
                      <br />
                      ДӨӨГҮЙ
                    </Text>
                  </Box>{' '}
                  {/*입금전*/}
                </Box>
                <ChevronRightIcon
                  w={dynamicSize.iconSize}
                  h={dynamicSize.iconSize}
                />
                <Box>
                  <Box>
                    <Center>
                      <Text fontSize={dynamicSize.attCount} as={'b'}>
                        {recentlyShoppingInfo?.status?.checking
                          ? recentlyShoppingInfo?.status?.checking
                          : 0}
                      </Text>
                    </Center>{' '}
                  </Box>
                  <Box>
                    <Text fontSize={dynamicSize.attTitle} textAlign={'center'}>
                      БЭЛДЭЖ
                      <br />
                      БАЙГАА
                    </Text>
                  </Box>{' '}
                  {/*배송준비중*/}
                </Box>
                <ChevronRightIcon
                  w={dynamicSize.iconSize}
                  h={dynamicSize.iconSize}
                />
                <Box>
                  <Box>
                    <Center>
                      <Text fontSize={dynamicSize.attCount} as={'b'}>
                        {recentlyShoppingInfo?.status?.approved
                          ? recentlyShoppingInfo?.status?.approved
                          : 0}
                      </Text>
                    </Center>{' '}
                  </Box>
                  <Box>
                    <Text fontSize={dynamicSize.attTitle} textAlign={'center'}>
                      ХҮРГЭЖ
                      <br />
                      БАЙГАА
                    </Text>
                  </Box>{' '}
                  {/*배송중*/}
                </Box>
                <ChevronRightIcon
                  w={dynamicSize.iconSize}
                  h={dynamicSize.iconSize}
                />
                <Box>
                  <Box>
                    <Center>
                      <Text
                        fontSize={dynamicSize.attCount}
                        color="red"
                        as={'b'}
                      >
                        {recentlyShoppingInfo?.status?.rejected
                          ? recentlyShoppingInfo?.status?.rejected
                          : 0}
                      </Text>
                    </Center>{' '}
                  </Box>
                  <Box>
                    <Text fontSize={dynamicSize.attTitle} textAlign={'center'}>
                      ХҮРГЭГД
                      <br />
                      СЭН
                    </Text>
                  </Box>{' '}
                  {/*배송완료*/}
                </Box>
              </Flex>
            </Box>
            <Box
              className="gray-bg"
              display={'flex'}
              width={'100px'}
              textAlign="center"
              height={'75px'}
              justifyContent={'center'}
              flexDirection={'column'}
              borderRadius={10}
              backgroundColor={'#f7f7f7'}
            >
              <Text fontSize={dynamicSize.attCount} as={'b'}>
                {recentlyShoppingInfo?.status?.canceled
                  ? recentlyShoppingInfo?.status?.canceled
                  : 0}
              </Text>
              <Text fontSize={dynamicSize.attTitle}>
                ЦУЦЛАГД
                <br />
                САН
              </Text>
            </Box>{' '}
            {/*취소*/}
          </Flex>
        </Box>
        {!isMobile ? (
          <RecentOrderInfo />
        ) : (
          <>
            <Text as={'b'} fontSize={'16px'}>
              МЭДЭЭЛЭЛ
            </Text>{' '}
            {/*나의 쇼핑 정보*/}
            <Divider borderColor={'black'} pt={2}></Divider>
            <Button
              my={2}
              bg={'none'}
              fontSize={'14px'}
              onClick={() =>
                (window.location.href = '/management/profile/order-list')
              }
            >
              ЗАХИАЛГА МЭДЭЭЛЭЛ
            </Button>{' '}
            {/*주문내역조회*/}
            <Divider />
            <Button
              my={2}
              bg={'none'}
              fontSize={'14px'}
              onClick={() =>
                (window.location.href = '/management/profile/delivery')
              }
            >
              ХҮРГЭЛТИЙН ХАЯГ
            </Button>
            {/*배송지 관리*/}
            <Divider />
            <Button
              my={2}
              bg={'none'}
              fontSize={'14px'}
              onClick={() =>
                (window.location.href = '/management/profile/modify')
              }
            >
              МЭДЭЭЛЭЛ ЗАСАХ
            </Button>{' '}
            {/*회원 정보 수정*/}
            <Divider />
            <Container pt={8}>
              <LogoutButton style={{ float: 'right' }} />
            </Container>
          </>
        )}
      </ProfileLayout>
    </>
  )
}
