import { Divider, Flex, Button, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { OrderItem } from './OrderItem'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { useRecoilValue } from 'recoil'
import {
  orderProductFormsState,
  isOrderFromCartState,
} from 'state/purchase/atoms'

type OrderProductListProps = {}

export const OrderProductList = ({}: OrderProductListProps) => {
  const orderProductForms = useRecoilValue(orderProductFormsState)
  const isOrderFromCart = useRecoilValue(isOrderFromCartState)
  const { goTo: goToCart } = usePrivateNavigate('/purchase/basket')

  return (
    <Box>
      <Text fontSize={'lg'} fontWeight={'bold'}>
        БҮТЭЭГДЭХҮҮН
      </Text>{' '}
      {/*주문상품*/}
      <Divider borderWidth={2} borderColor={'gray'} />
      {orderProductForms.map((orderProductForm, index) => (
        <OrderItem key={index} orderProductForm={orderProductForm} />
      ))}
      {isOrderFromCart && (
        <Flex alignItems={'center'} mt={4}>
          <Text>
            *Сагснаас захиалсан бүтээгдэхүүнийхээ тоог өөрчлөх боломжтой.
          </Text>{' '}
          {/*주문상품 수량 변경은 장바구니 페이지에서 가능합니다.*/}
          <Button
            colorScheme="gray"
            variant={'outline'}
            size={'md'}
            w={'177px'}
            ml={2}
            onClick={() => goToCart()}
          >
            САГС РУУ
          </Button>{' '}
          {/*장바구니로 이동*/}
        </Flex>
      )}
    </Box>
  )
}
