import { Box, Text, Textarea } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
export function TermsOfUse() {
  const [termofusePolicy, setTermofusePolicy] = useState('')
  useEffect(() => {
    fetch('/termofusePolicy.txt')
      .then((response) => response.text())
      .then((data) => setTermofusePolicy(data))
      .catch((error) => console.error('Error fetching the text file:', error))
  }, [])
  return (
    <Box pb={10}>
      <Box textAlign={'center'} width="100%" py={10}>
        <Text as={'b'} fontSize={'18px'}>
          ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ
        </Text>{' '}
        {/*이용약관*/}
      </Box>
      <div
        style={{ fontSize: '12px', padding: '10px' }}
        dangerouslySetInnerHTML={{
          __html: termofusePolicy.replace(/\n/g, '<br/>'),
        }}
      />
    </Box>
  )
}
