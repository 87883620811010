import {
  Box,
  Divider,
  Grid,
  GridItem,
  Input,
  ring,
  Text,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { HintText } from 'views/component/HintText'
import { OrderFomikValuesType } from '../type'
import { useOwnerAccount } from 'common/api/domains/common/commonQuery'

type OrderInfoProps = {}

export const OrderInfo = ({}: OrderInfoProps) => {
  const { values, setFieldValue, errors } =
    useFormikContext<OrderFomikValuesType>()
  const { data: ownerAccount, isLoading } = useOwnerAccount()

  useEffect(() => {
    if (isLoading || !ownerAccount) return
    const [accountNumber, accountBankName, accountHolder] =
      ownerAccount.split(' ')
    setFieldValue('accountBankName', accountBankName || '')
    setFieldValue('accountHolder', accountHolder || '')
    setFieldValue('accountNumber', accountNumber || '')
  }, [isLoading])

  return (
    <Box pr={2} pl={2} pb={20}>
      <Text fontSize={'lg'} fontWeight={'bold'} mt={2} mb={1}>
        ТӨЛБӨРИЙН НӨХЦӨЛ
      </Text>{' '}
      {/*결제정보*/}
      <Divider borderColor={'gray'} />
      <Grid
        templateColumns={'128px 1fr'}
        rowGap={1}
        mt={3}
        display={'flex'}
        flexDirection={'column'}
      >
        <GridItem color={'#505050'} mt={2} fontSize={14} as={'b'}>
          *ШИЛЖҮҮЛЭХ ДАНС
        </GridItem>{' '}
        {/*입금은행*/}
        <GridItem
          mt={2}
          fontSize={14}
        >{`${values.accountBankName} ${values.accountNumber} | ${values.accountHolder}`}</GridItem>
        <GridItem color={'#505050'} mt={1} fontSize={14} as={'b'}>
          *ДАНС ЭЗЭМШИГЧИЙН НЭР
        </GridItem>{' '}
        {/*입금자명*/}
        <GridItem display={'flex'} flexDirection={'column'}>
          <Input
            value={values.depositorName}
            onChange={(e) => setFieldValue('depositorName', e.target.value)}
            size={'sm'}
            fontSize={14}
          />
          <HintText
            hintType={'error'}
            hint={errors.depositorName}
            preOccupySpace={false}
            fontSize="s"
          />
          <HintText
            hintType={'error'}
            hint={
              'Данс эзэмшигчийн нэрийг зөв бичиж оруулна уу, тэдгээр нь таарвал гүйлгээг 1 минутын дотор баталгаажуулна. Хэрэв энэ дээр бичсэн нэр, хадгаламж эзэмшигчийн нэр өөр байвал баталгаажуулалт удааширна.'
            }
            fontSize="s"
          />
        </GridItem>
      </Grid>
    </Box>
  )
}
