import {
  Box,
  Flex,
  Button,
  useDisclosure,
  Container,
  Image,
  Grid,
  GridItem,
  Divider,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
  Stack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  LinkBox,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useMediaQuery } from 'react-responsive'
import 'common/scss/common.scss'
import { Logo } from './Logo'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { LogoutButton } from 'views/auth/component/LogoutButton'
import { useEffect, useState } from 'react'
import { useAuth } from 'common/hooks/useAuth'
import { useCategoriesQuery } from 'common/api/domains/goods/goodsQuery'
import { Link } from 'react-router-dom'
import { blacklist } from 'validator'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { color } from 'framer-motion'

export default function WithSubnavigation() {
  // const [isLogin, setLoginState] = useRecoilState(isLoginState)
  const { user } = useAuth()
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure()
  const isMobile = useRecoilValue(clientIsMobile)

  const { href: basketHref } = usePrivateNavigate('/purchase/basket')
  const { data: categories } = useCategoriesQuery()
  const [name, setName] = useState(user?.name || '')

  useEffect(() => {
    setName(user?.name || '')
  }, [user?.id])
  return isMobile !== true ? (
    <Box pb={3}>
      <Container maxW={'1200px'} py={5} pb={2}>
        <Grid w={'1200px'} templateColumns="repeat(3, 1fr)" gap={6}>
          <GridItem w="100%" h="90" />
          <GridItem w="100%" h="150">
            <Center>
              <Link to="/">
                <Image className="logo" w={150} h={150} />
              </Link>
            </Center>
          </GridItem>
          <GridItem w="100%" h="10">
            <div style={{ float: 'right' }}>
              <Flex>
                {user ? (
                  <>
                    <Link to={basketHref}>САГС</Link> {/*장바구니*/}
                    <Center w="15px">
                      <Divider orientation="vertical" borderColor="gray.400" />
                    </Center>
                  </>
                ) : (
                  ''
                )}
                {!user ? (
                  <>
                    <Link to="/auth/login">НЭВТРЭХ</Link> {/*로그인*/}
                    <Center w="15px">
                      <Divider orientation="vertical" borderColor="gray.400" />
                    </Center>
                    <Link to="/auth/sign-in">БҮРТГҮҮЛЭХ</Link> {/*회원가입*/}
                  </>
                ) : (
                  ''
                )}
                {user ? (
                  <>
                    <Link to="/management/profile">МИНИЙ МЭДЭЭЛЭЛ</Link>{' '}
                    {/*마이페이지*/}
                    <Center w="15px">
                      <Divider orientation="vertical" borderColor="gray.400" />
                    </Center>{' '}
                    <LogoutButton style={{ color: 'none' }} />
                  </>
                ) : (
                  ''
                )}
              </Flex>
            </div>
          </GridItem>
        </Grid>
        <Flex w={'1200px'}>
          {/* <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem icon={<AddIcon />} command="⌘T">
                New Tab
              </MenuItem>
              <MenuItem icon={<AddIcon />} command="⌘N">
                New Window
              </MenuItem>
              <MenuItem icon={<AddIcon />} command="⌘⇧N">
                Open Closed Tab
              </MenuItem>
              <MenuItem icon={<AddIcon />} command="⌘O">
                Open File...
              </MenuItem>
            </MenuList>
          </Menu> */}
          {categories && (
            <Stack
              direction={['column', 'row']}
              spacing="24px"
              pl={1}
              width={'100%'}
              justifyContent={'space-between'}
              pb={2}
            >
              {categories.map((category) => (
                <Link
                  key={category.id}
                  to={`/goods/categories/${category.id}/products`}
                >
                  <Text as={'b'} fontSize={'16px'}>
                    {category.name}
                  </Text>
                </Link>
              ))}
            </Stack>
          )}
        </Flex>
      </Container>
      <Divider borderColor="gray.400" />
    </Box>
  ) : (
    <>
      <Box pb={3}>
        <Container maxW={'375px'} py={5} pb={2} pl={0} pr={0}>
          <Grid w={'375px'} templateColumns="repeat(3, 1fr)" gap={2}>
            <GridItem w="100%" h="90" />
            <GridItem w="100%" h="90">
              <Center>
                <Logo />
              </Center>
            </GridItem>
            <GridItem w="100%" h="10"></GridItem>
          </Grid>
          {categories && (
            <Box w={'375px'} display={'flex'}>
              <IconButton
                bgColor={'white'}
                aria-label="sidebar"
                icon={<Image className="hamburgerIcon" boxSize="24px" />}
                onClick={onOpen}
              />
              <Box
                overflowX={'auto'}
                display={'flex'}
                css={{
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  '-ms-overflow-style': 'none', // IE and Edge
                  'scrollbar-width': 'none', // Firefox
                }}
                alignSelf={'center'}
              >
                {categories.map((category) => (
                  <Link
                    key={category.id}
                    // display={'inline-block'}
                    // alignItems="center"
                    // justifyContent="center"
                    // ml={4}
                    to={`/goods/categories/${category.id}/products`}
                  >
                    <Text ml={4}>{category.name}</Text>
                  </Link>
                ))}
              </Box>
            </Box>
          )}
        </Container>
        <Divider borderColor="gray.400" />

        <Drawer
          placement="left"
          onClose={onClose}
          isOpen={isOpen}
          size={'full'}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton color="white" />
            <DrawerHeader backgroundColor={'blue.600'}>
              {!user ? (
                <>
                  <HStack m={2} mt={10} justifyContent={'space-between'}>
                    <Button
                      rightIcon={<ChevronRightIcon color={'white'} />}
                      bg={'none'}
                      color={'white'}
                      fontSize={'18px'}
                      onClick={() => (window.location.href = '/auth/login')}
                      _hover={{ color: '#2b6cb0', background: 'white' }}
                    >
                      НЭВТРЭХ
                    </Button>{' '}
                    {/*로그인 해주세요.*/}
                    <Button
                      fontSize={18}
                      bg={'none'}
                      color={'white'}
                      _hover={{ color: '#2b6cb0', background: 'white' }}
                      onClick={() => (window.location.href = '/auth/sign-in')}
                    >
                      БҮРТГҮҮЛЭХ
                    </Button>{' '}
                    {/*회원가입*/}
                  </HStack>
                </>
              ) : (
                <>
                  <HStack mt={10} justifyContent={'space-between'}>
                    <Text color={'white'}>{name}</Text>
                  </HStack>
                </>
              )}
            </DrawerHeader>
            <DrawerBody>
              {user ? (
                <>
                  <Link to={basketHref}>
                    <Button
                      rightIcon={<ChevronRightIcon color="gray" />}
                      fontSize={'18px'}
                      onClick={() =>
                        (window.location.href = `/users/${user.id}/purchase/basket/`)
                      }
                      bg={'none'}
                      width={'100%'}
                      borderBottom={'0.5px solid #2d2d2d'}
                      borderRadius={'0px'}
                      _hover={{ color: '#2b6cb0' }}
                      justifyContent={'flex-start'}
                      height={'45px'}
                    >
                      САГС
                    </Button>{' '}
                    {/*장바구니*/}
                  </Link>
                  <Button
                    rightIcon={<ChevronRightIcon color="gray" />}
                    fontSize={'18px'}
                    bg={'none'}
                    onClick={() =>
                      (window.location.href = '/management/profile')
                    }
                    width={'100%'}
                    borderBottom={'0.5px solid #2d2d2d'}
                    borderRadius={'0px'}
                    _hover={{ color: '#2b6cb0' }}
                    justifyContent={'flex-start'}
                    height={'45px'}
                  >
                    МИНИЙ МЭДЭЭЛЭЛ
                  </Button>{' '}
                  {/*마이페이지*/}
                </>
              ) : (
                <></>
              )}
              <Text mt={10} ml={3} mb={5} fontSize={'18px'} fontWeight={500}>
                БҮТЭЭГДЭХҮҮН
              </Text>{' '}
              {/*상품*/}
              <Container ml={3} fontSize={16} fontWeight={500}>
                {categories && (
                  <VStack spacing={4} align="stretch">
                    {categories.map((category) => (
                      <Link
                        key={category.id}
                        to={`/goods/categories/${category.id}/products`}
                        onClick={() => onClose()}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </VStack>
                )}
              </Container>
              {user ? (
                <>
                  <Center w="15px">
                    <Divider orientation="vertical" borderColor="gray.400" />
                  </Center>
                  <Box mt={20} textAlign={'right'}>
                    <LogoutButton style={{ color: '#AAAAAA' }} />
                  </Box>
                </>
              ) : (
                ''
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  )
}
