import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tag,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { AuthLayout } from './AuthLayout'
import { SignOutHeader } from './component/SignOutHeader'
import { useNavigate } from 'react-router-dom'
import { useSignOutQuery } from 'common/api/domains/auth/authQuery'
import { isEmpty, isLogin } from 'common/util/string-utils'
export function SignOut() {
  const [isChecked, setIsChecked] = useState(false)
  const isMobile = useRecoilValue(clientIsMobile)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate, isLoading, isError, isSuccess, error } = useSignOutQuery()
  const navigate = useNavigate()
  const signOut = () => {
    mutate(undefined, {
      onSuccess: (data) => {
        localStorage.clear()
        window.location.href = '/'
      },
      onError: (error) => {},
      onSettled: (data, error) => {},
    })
  }
  useEffect(() => {
    isLogin(navigate)
  }, [])

  const finalRef = React.useRef(null)
  return (
    <>
      <AuthLayout pageName="БҮРТГЭЛ ЦУЦЛАХ" pageHeader={<SignOutHeader />}>
        <Textarea
          value={
            'ТАНЫГ ГИШҮҮНЧЛЭЛЭЭ ЦУЦЛАХ ҮЕД ТАНЫ ХУВИЙН МЭДЭЭЛЭЛ БОЛОН ХУДАЛДАН АВАЛТЫН ТӨЛБӨРИЙН ТҮҮХ УСТАХ БӨГӨӨД ТӨРӨЛ БҮРИЙН ҮР ӨГӨӨЖИЙГ СЭРГЭЭХ БОЛОМЖГҮЙ.' /*탈퇴경고내용*/
          }
          size="sm"
          readOnly
          resize={'none'}
          m={0}
          backgroundColor={'RGBA(0, 0, 0, 0.06)'}
          borderRadius={4}
          rows={8}
          w={isMobile ? '100%' : '100%'}
          h={'auto'}
          textAlign={'left'}
        />
      </AuthLayout>
      <Box w={'100%'} display={'flex'} justifyContent="center">
        <Stack w={isMobile ? '80%' : '50%'} align={'center'}>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            ЗА, ОЙЛГОЛОО. {/*네, 주의사항을 모두 확인하였습니다.*/}
          </Checkbox>
          <Button
            colorScheme="blue"
            onClick={onOpen}
            w={isMobile ? '100%' : '50%'}
            mt={3}
            isDisabled={!isChecked}
            _disabled={{ bg: 'gray.300', cursor: 'not-allowed' }}
            _hover={{}}
          >
            БҮРТГЭЛ ЦУЦЛАХ {/*탈퇴하기*/}
          </Button>
        </Stack>
      </Box>

      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w={'90%'}>
          <ModalHeader></ModalHeader>

          <ModalBody>
            <Box textAlign={'center'} my={5}>
              ТА БҮРТГЭЛЭЭ ЦУЦЛАХЫГ ХҮСЭЖ БАЙНА УУ?{' '}
              {/*회원탈퇴를 하시겠습니까?*/}
            </Box>
          </ModalBody>

          <ModalFooter p={0} h={100}>
            <Button
              colorScheme="blue"
              onClick={signOut}
              w={'50%'}
              ml={5}
              mr={5}
            >
              ХҮСЭЖ БАЙНА {/*확인*/}
            </Button>
            <Button
              colorScheme="blue"
              onClick={onClose}
              variant="outline"
              w={'50%'}
              ml={5}
              mr={5}
            >
              ГАРАХ {/*취소*/}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
