import { ChakraProvider } from '@chakra-ui/react'
import './App.css'
import { AppRouter } from './common/routes/AppRouter'
import { useRecoilState } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useAuth } from 'common/hooks/useAuth'
import { useEnv } from 'common/hooks/useEnv'
import theme from './thema/FontThema'

// import {
//   accessTokenState,
//   isLoginState,
//   refreshTokenState,
//   userNameState,
//   userPhoneState,
// } from 'state/auth/atoms'

function App() {
  const [isMobile, setIsMobile] = useRecoilState(clientIsMobile)
  // const [isLogin, setIsLogin] = useRecoilState(isLoginState)
  // const [phoneNumber, setUserPhoneNumber] = useRecoilState(userPhoneState)
  // const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState)
  // const [accessToken, setAccessToken] = useRecoilState(accessTokenState)
  const { isReady } = useEnv()
  const { refreshUserState } = useAuth()

  let clientWindow = useMediaQuery({ query: '(max-width: 624px)' })
  useEffect(() => {
    setIsMobile(clientWindow)
    // initLoginState()
  }, [clientWindow])

  useEffect(() => {
    refreshUserState()
  }, [])

  // const initLoginState = () => {
  //   if (
  //     localStorage.getItem('jwt-token') &&
  //     localStorage.getItem('refresh-token')
  //   ) {
  //     setIsLogin(true)
  //   }
  // }

  if (!isReady) return null

  return (
    <ChakraProvider>
      <AppRouter />
    </ChakraProvider>
  )
}

export default App
