import {
  useNumberInput,
  HStack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'

type CountInputProps = {
  value?: number
  step?: number
  defaultValue?: number
  min?: number
  max?: number
  onChange: (value: string) => void

  width?: string
  buttonSize?: string
}

export const CountInput = ({
  value,
  step = 1,
  defaultValue = 1,
  min = 1,
  max = 100,
  onChange,
  width = '128px',
  buttonSize = 'sm',
}: CountInputProps) => {
  const {
    value: inputValue,
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
  } = useNumberInput({
    step,
    defaultValue,
    min,
    max,
    value,
    onChange,
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  return (
    <InputGroup w={width}>
      <InputLeftElement>
        <Button {...dec} size={buttonSize} borderRadius={15}>
          -
        </Button>
      </InputLeftElement>
      <Input {...input} textAlign={'center'} />
      <InputRightElement>
        <Button {...inc} size={buttonSize} borderRadius={15}>
          +
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}
