import { TriangleUpIcon } from '@chakra-ui/icons'
import { Box, Heading, Text } from '@chakra-ui/react'

export function SigninHeader() {
  return (
    <>
      <Heading mb={3} fontSize={20} textAlign={'center'}>
        БҮРТГҮҮЛЭХ {/*회원가입*/}
      </Heading>
      <Box justifyContent={'center'} fontSize={12}>
        <Text fontWeight={100} display={'contents'}>
          01
        </Text>
        &nbsp;
        <Text fontWeight={600} display={'contents'}>
          ХЭРЭГЛЭХ НӨХЦӨЛ {/*약관동의*/}
        </Text>
        <TriangleUpIcon transform="rotate(90deg)" mb={2} mx={3} />
        <Text fontWeight={100} display={'contents'}>
          02
        </Text>
        &nbsp;
        <Text fontWeight={600} display={'contents'}>
          МЭДЭЭЛЭЛ {/*정보입력*/}
        </Text>
        {/*<TriangleUpIcon transform="rotate(90deg)" mb={2} mx={3} />
        <Text fontWeight={100} display={'contents'}>
          03
        </Text>
        &nbsp;
        <Text fontWeight={600} display={'contents'}>
          БҮРТГЭЛ ДУУССАН 가입완료
        </Text> */}
      </Box>
    </>
  )
}
