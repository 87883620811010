import { useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { ProductListMobile } from './mobile'
import { ProductListDesktop } from './desktop'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

export const ProductList = () => {
  const isMobile = useRecoilValue(clientIsMobile)

  if (isMobile) return <ProductListMobile />
  return <ProductListDesktop />
}
