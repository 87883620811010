import { Box, Heading, Text } from '@chakra-ui/react'

export function LoginHeader() {
  return (
    <>
      <Heading mb={3} fontSize={25} textAlign={'center'}>
        НЭВТРЭХ
      </Heading>{' '}
      {/*로그인*/}
    </>
  )
}
