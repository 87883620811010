// src/axiosConfig.js
import axios from 'axios'

export const createAxiosInstance = (secure: boolean = false) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
  })

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('jwt-token')
      const mallId = localStorage.getItem('mall-id')
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      if (mallId) {
        config.headers['mall-id'] = `${mallId}`
      } else {
        config.headers['mall-id'] = process.env.REACT_APP_API_MALL_ID
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  if (!secure) {
    return axiosInstance
  }

  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const originalRequest = error.config
      if (
        error.response.status === 401 &&
        originalRequest.url !== '/auth/token/access'
      ) {
        originalRequest._retry = true
        try {
          const refreshToken = localStorage.getItem('refresh-token')
          if (!refreshToken) {
            throw new Error('No refresh token available')
          }
          const response = await axiosInstance.post('/auth/token/access', {
            token: refreshToken,
          })
          const newAccessToken = response.data
          localStorage.setItem('jwt-token', newAccessToken)
          axiosInstance.defaults.headers.common['Authorization'] =
            `Bearer ${newAccessToken}`
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
          return axiosInstance(originalRequest)
        } catch (refreshError) {
          localStorage.clear()

          return Promise.reject(refreshError)
        }
      }
      if (originalRequest.url !== '/profile/password/change') {
        localStorage.clear()
        window.location.href = '/'
      }
      return Promise.reject(error)
    },
  )

  return axiosInstance
}
