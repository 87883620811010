import { ReactNode, useEffect } from 'react'

import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { relative } from 'path'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { useOwnerAccount } from 'common/api/domains/common/commonQuery'

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'14px'} mb={2}>
      {children}
    </Text>
  )
}

export default function Footer() {
  const { data: ownerAccount } = useOwnerAccount()
  useEffect(() => {
    console.log({ ownerAccount })
  }, [ownerAccount])
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 624px)' })

  return (
    <>
      <Divider borderColor="gray.400" />
      {isTabletOrMobile !== true ? (
        <>
          <Container maxW={'1200px'} py={5}>
            <Grid w={'1200px'} templateColumns="repeat(2, 1fr)" pb={10}>
              <GridItem w="100%">
                <Flex fontWeight={600} justifyContent={'space-around'}>
                  <Text mr={2}>КОМПАНИ {/*회사소개*/}</Text>

                  <Link to="/terms-of-use">
                    ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ {/*이용약관*/}
                  </Link>

                  <Link to="/privacy-policy">
                    АЮУЛГҮЙ АЖИЛЛАГАА {/*개인정보처리방침*/}
                  </Link>
                </Flex>
              </GridItem>
              <GridItem w="100%">
                <Box
                  style={{
                    float: 'right',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  gap={5}
                >
                  {localStorage.getItem('mall-id') !== '3' && (
                    <Link to="https://www.facebook.com/bilegmeatmarketkorea">
                      {' '}
                      <Image className="faceBookLogo" boxSize="25px" />
                    </Link>
                  )}
                </Box>
              </GridItem>
            </Grid>
            <Grid w={'1200px'} templateColumns="repeat(3, 1fr)">
              <Box>
                <Stack>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Stack gap={2}>
                      <Flex fontSize={'16px'} as={'b'}>
                        <Text pr={5}>БИЛЭГ МАХ</Text> {/*빌렉*/}
                      </Flex>
                      <Flex fontSize={'14px'}>
                        <Text color={'#2b2b2b'}>КОМПАНИ No.&nbsp;</Text>{' '}
                        {/*사업자번호*/}
                        <Text> 1002-260-528545</Text>{' '}
                        <Text>[사업자정보확인]&nbsp;</Text>
                      </Flex>
                      <Flex fontSize={'14px'}>
                        <Text color={'#2b2b2b'}>ЗАХИАЛГА БИЗНЕС No.&nbsp;</Text>{' '}
                        {/*통신판매업*/}
                        <Text>0000-000-0000&nbsp;&nbsp;</Text>
                      </Flex>
                      <Text as={'b'} fontSize={'11px'}>
                        COPYRIGHT © БИЛЭГ МАХ. ALL RIGHT RESERVED.
                      </Text>
                    </Stack>
                  </div>
                </Stack>
              </Box>
              <Box borderLeft="1px solid #E2E2E2" justifyContent="center">
                <Stack
                  textAlign={'left'}
                  gap={0}
                  color={'#666666'}
                  marginLeft={70}
                >
                  <Text fontSize={'16px'} color={'black'} as={'b'}>
                    {' '}
                    {/*CS SENTER*/}
                    ХОЛБОО БАРИХ УТАС
                  </Text>
                  <Text
                    fontWeight={'400'}
                    fontSize={'14px'}
                    color={'black'}
                    as={'b'}
                  >
                    010-0000-0000, 010-0000-0000
                  </Text>
                  <Text
                    fontWeight={'400'}
                    fontSize={'14px'}
                    color={'black'}
                    as={'b'}
                  >
                    10:00-18:00
                  </Text>
                  <Text
                    fontWeight={'400'}
                    fontSize={'16px'}
                    color={'black'}
                    as={'b'}
                  >
                    БЯМБА ГАРАГТ АМАРНА
                  </Text>{' '}
                  {/*휴무*/}
                </Stack>
              </Box>
              <Box borderLeft="1px solid #E2E2E2">
                <Stack
                  textAlign={'left'}
                  gap={0}
                  color={'#666666'}
                  marginLeft={70}
                  fontSize={'14px'}
                >
                  <Text fontSize={'16px'} color={'black'} as={'b'}>
                    {' '}
                    {/*BANK INFO*/}
                    ДАНСНЫ ДУГААР
                  </Text>
                  <Text
                    fontWeight={'500'}
                    fontSize={'16px'}
                    color={'black'}
                    as={'b'}
                    pb={2}
                  >
                    WOORI BANK({ownerAccount?.split(' ')[1]}){' '}
                    {ownerAccount?.split(' ')[0]}
                    <br /> {ownerAccount?.split(' ')[2]}
                  </Text>
                  {/*<Text fontWeight={'400'} fontSize={'18px'} color={'black'} as={'b'}>
                    {ownerAccount?.split(' ')[1]}
                  </Text>
                  <Text fontWeight={'400'} >
                    ДАНС ЭЗЭМШИГЧ: {ownerAccount?.split(' ')[2]}
                  </Text>*/}
                </Stack>
              </Box>
            </Grid>
          </Container>
        </>
      ) : (
        <>
          <Container maxW={'6xl'} py={5} textAlign={'center'}>
            <Flex
              justifyContent={'space-evenly'}
              fontSize={10}
              mb={5}
              fontWeight={600}
            >
              <Text>КОМПАНИ {/*회사소개*/}</Text>
              <Link to="/terms-of-use">ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ {/*이용약관*/}</Link>
              <Link to="/privacy-policy">
                АЮУЛГҮЙ АЖИЛЛАГАА {/*개인정보처리방침*/}
              </Link>
            </Flex>
            <Grid
              w={'100%'}
              templateColumns="repeat(1, 1fr)"
              fontSize={10}
              fontWeight={600}
            >
              <GridItem w="100%" h="10" textAlign={'center'} ml={0}>
                <Flex justifyContent={'center'}>
                  <Text>БИЛЭГ МАХ</Text> {/*빌렉*/}
                </Flex>
                <Flex justifyContent={'center'}>
                  <Text color={'#A2A2A2'}>КОМПАНИ No.&nbsp;</Text>{' '}
                  {/*사업자번호*/}
                  <Text>000-0000-0000</Text>&nbsp;
                  <Text>[사업자정보확인]</Text>
                </Flex>
                <Flex justifyContent={'center'}>
                  <Text color={'#A2A2A2'}>ЗАХИАЛГА БИЗНЕС No.&nbsp;</Text>{' '}
                  {/*통신판매업*/}
                  <Text>000-0000-0000</Text>
                </Flex>
              </GridItem>
            </Grid>
            <Text w={'100%'} fontSize={13} mt={5}>
              COPYRIGHT © БИЛЭГ МАХ. ALL RIGHT RESERVED.
            </Text>
          </Container>
        </>
      )}
    </>
  )
}
