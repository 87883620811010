import { Flex, Box, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { OrderAddon } from './OrderAddon'
import { DeliveryAddress } from './DeliveryAddress'
import { OrderProductList } from './OrderProductList'
import { OrderInfo } from './OrderInfo'
import { Formik } from 'formik'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { AddressForm } from 'common/hooks/useAddress'
import { OrderValidationSchema } from 'common/validation/OrderValidationSchema'
import { useCreateOrder } from 'common/api/domains/purchase/purchaseMutation'
import { OrderProductForm } from 'domain/purchase/forms'
import { useAuth } from 'common/hooks/useAuth'
import { useRecoilState } from 'recoil'
import {
  orderProductFormsState,
  isOrderFromCartState,
} from 'state/purchase/atoms'
import {
  CreateOrderProductRequestDTO,
  CreateOrderRequestDTO,
} from 'domain/purchase/request.dto'
import { OrderFomikValuesType } from '../type'

export const OrderDesktop = () => {
  const { goTo: goToOrderDetail } = usePrivateNavigate('/purchase/orders')
  const { mutate: createOrder } = useCreateOrder()
  const { user } = useAuth()
  const [orderProductForms, setOrderProductForms] = useRecoilState(
    orderProductFormsState,
  )
  const [isOrderFromCart, setIsOrderFromCart] =
    useRecoilState(isOrderFromCartState)

  const onSubmit = async (values: OrderFomikValuesType) => {
    if (!user) {
      return
    }
    const createOrderRequestDto: CreateOrderRequestDTO = {
      userId: user.id,
      ...values.address,
      depositorName: values.depositorName,
      productCost: values.productCost,
      deliveryCost: values.deliveryCost,
      discount: values.discount,
      accountBankName: values.accountBankName,
      accountHolder: values.accountHolder,
      accountNumber: values.accountNumber,
      isOrderFromCart,
    }
    const createOrderProductRequestDtos: CreateOrderProductRequestDTO[] =
      orderProductForms.map((orderProductForm) => ({
        count: orderProductForm.count,
        productId: orderProductForm.productId,
        price: orderProductForm.price,
        productName: orderProductForm.productName,
        unit: orderProductForm.unit,
      }))
    await createOrder(
      {
        createOrderRequestDto,
        createOrderProductRequestDtos,
      },
      {
        onSuccess: (order) => {
          goToOrderDetail({ path: `${order.id}/receipt` })
        },
        onError: (e) => {
          console.log(e)
        },
      },
    )
  }

  useEffect(() => {
    return () => {
      setOrderProductForms([])
      setIsOrderFromCart(false)
    }
  }, [])

  return (
    <Box w={'1200px'}>
      <Text m={8} fontSize={'xl'} fontWeight={'bold'} textAlign={'center'}>
        ЗАХИАЛГА/ТӨЛБӨР
      </Text>{' '}
      {/*주문/결제*/}
      <Formik
        initialValues={{
          orderProductForms: orderProductForms || [],
          address: {
            name: '',
            postcode: '',
            address1: '',
            address2: '',
            phone: '',
          },
          productCost: 0,
          deliveryCost: 0,
          discount: 0,
          accountBankName: '',
          accountHolder: '',
          accountNumber: '',
          depositorName: '',
        }}
        onSubmit={onSubmit}
        validationSchema={OrderValidationSchema}
      >
        <Flex>
          <Box flex={1} mr={12}>
            <OrderProductList />
            <Box h={16} />
            <DeliveryAddress />
            <Box h={16} />
            <OrderInfo />
            <Box h={32} />
          </Box>
          <OrderAddon />
        </Flex>
      </Formik>
    </Box>
  )
}
