import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import React from 'react'
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode'

type PostcodeModalProps = {
  defaultQuery?: string
  isOpen: boolean
  onClose: () => void
  onComplete: (address: Address) => void
}

export const PostcodeModal = ({
  defaultQuery,
  isOpen,
  onClose,
  onComplete,
}: PostcodeModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>ХАЯГ ХАЙХ</ModalHeader> {/*주소 검색*/}
        <ModalCloseButton />
        <ModalBody>
          <DaumPostcodeEmbed
            defaultQuery={defaultQuery}
            onComplete={onComplete}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
