import { ImageType } from 'common/util/enum'
import { ImageResponseDTO } from './response.dto'

export class ImageModel {
  id!: number
  path!: string
  type!: ImageType
  createdAt!: Date
  updatedAt!: Date

  constructor(imageResponseDto: ImageResponseDTO) {
    this.id = imageResponseDto.id
    this.path = imageResponseDto.path
    this.type = imageResponseDto.type
    this.createdAt = imageResponseDto.created_at
    this.updatedAt = imageResponseDto.updated_at
  }
}
