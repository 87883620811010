import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { ReactNode } from 'react'
interface AuthLayoutProps {
  children: ReactNode
  pageName: string
  pageHeader: ReactNode
}
export function AuthLayout({
  children,
  pageName,
  pageHeader,
}: AuthLayoutProps) {
  const location = useLocation()
  const isMobile = useRecoilValue(clientIsMobile)
  return (
    <>
      <Grid
        w={isMobile ? '375px' : '1200px'}
        templateColumns="repeat(1, 1fr)"
        gap={6}
      >
        <GridItem w="100%" mb={5} pr={4}>
          <div style={{ textAlign: 'right' }}>
            <Link to="/">HOME</Link>&nbsp;/&nbsp;
            <div
              style={{
                display: 'inline-block',
                fontWeight: 600,
              }}
            >
              {pageName}
            </div>
          </div>
        </GridItem>
      </Grid>
      <Grid w={isMobile ? '375px' : '1200px'} templateColumns="1fr " gap={6}>
        <GridItem w="100%">
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {pageHeader}
            <Flex
              flexDirection="column"
              // border={'1px solid lightgray'}
              // borderRadius={8}
              // boxShadow="lg"
              w={isMobile ? '95%' : '40%'}
              h={isMobile ? '90%' : '70%'}
              mb={10}
            >
              {children}
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}
