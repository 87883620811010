import { Button, Text } from '@chakra-ui/react'
import { useLogoutQuery } from 'common/api/domains/auth/authQuery'
import { useAuth } from 'common/hooks/useAuth'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
// import { isLoginState } from 'state/auth/atoms'
interface LogoutButtonProps {
  style?: React.CSSProperties
}
export function LogoutButton({ style }: LogoutButtonProps) {
  // const [isLogin, setLoginState] = useRecoilState(isLoginState)
  const { logout: clientLogout } = useAuth()
  const { mutate, isLoading, isError, isSuccess, error } = useLogoutQuery()
  // const navigate = useNavigate()
  // const location = useLocation()
  const logout = () => {
    mutate(true, {
      onSuccess: () => {},
      onError: (error) => {},
      onSettled: (data, error) => {
        clientLogout()
        // setLoginState(false)
        window.location.href = '/goods/categories/1/products'
      },
    })
  }
  return (
    <Link onClick={logout} style={style} to={''}>
      ГАРАХ {/*로그아웃*/}
    </Link>
  )
}
