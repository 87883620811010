import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { BasketAddon } from './BasketAddon'
import { BasketList } from './BasketList'

export const BasketMobile = () => {
  return (
    <Box w={'375px'}>
      <Text m={8} fontSize={'xl'} fontWeight={'bold'} textAlign={'center'}>
        САГС
      </Text>
      <Flex flexDirection={'column'}>
        <BasketList />
        <Box h={8} />
        <BasketAddon />
      </Flex>
    </Box>
  )
}
