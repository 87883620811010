import { Box, Image, Text } from '@chakra-ui/react'
import { ProductModel } from 'domain/goods/model'
import React, { useEffect } from 'react'
import { MoneyText } from 'views/component/MoneyText'

type ProductItemProps = {
  product: ProductModel
}

export const ProductItem = ({ product }: ProductItemProps) => {
  return (
    <Box width={'272px'} mb={4}>
      <Image
        src={product.thumbnail?.path}
        width={'272px'}
        height={'272px'}
        borderRadius={10}
      />
      <Box p={2}>
        <Text fontWeight={'700'} fontSize={18}>
          {product.name}
        </Text>
        <MoneyText
          amount={product.price}
          unit={'₩'}
          textProps={{ fontWeight: 'bold', fontSize: 16 }}
        />
        <Text mb={1} fontWeight={500} fontSize={14} color={'gray'}>
          {product.unit_size}
        </Text>
      </Box>
    </Box>
  )
}
