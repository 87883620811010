import { Text, TextProps } from '@chakra-ui/react'
import React, { useMemo } from 'react'

type Props = {
  textProps?: TextProps
  amount: number | string
  unit: string
  prefix?: string
  suffix?: string
}

export const MoneyText = ({
  textProps,
  amount,
  unit,
  prefix,
  suffix,
}: Props) => {
  const convertedText = useMemo(() => {
    let n = Number(amount)
    if (isNaN(n)) return amount
    return n.toLocaleString('en')
  }, [amount, unit])
  return (
    <Text {...textProps}>
      {`${prefix ? prefix : ''} ${convertedText} ${unit} ${suffix ? suffix : ''}`}
    </Text>
  )
}
