import { Tabs, TabList, Tab, TabPanels, TabPanel, Text } from '@chakra-ui/react'
import { ProductModel } from 'domain/goods/model'
import React from 'react'
import { DeliveryInfo } from './panel/DeliveryInfo'

const selectedStyle = {
  fontWeight: 'bold',
  color: 'black',
  borderTop: '2px solid black',
  borderBottom: 'none',
}
type ProductItemProps = {
  product: ProductModel
}

export const ProductInformationTabs = ({ product }: ProductItemProps) => {
  return (
    <Tabs isFitted variant={'enclosed'} borderWidth={0}>
      <TabList borderWidth={0}>
        <Tab
          borderBottom={'2px solid gray'}
          _selected={{ ...selectedStyle, borderRight: '2px solid gray' }}
        >
          МЭДЭЭЛЭЛ
        </Tab>{' '}
        {/*상품정보*/}
        <Tab
          borderBottom={'2px solid gray'}
          _selected={{ ...selectedStyle, borderLeft: '2px solid gray' }}
        >
          ХҮРГЭЛТ
        </Tab>{' '}
        {/*배송정보*/}
      </TabList>
      <TabPanels>
        <TabPanel>
          {product
            ? product.product_images

                ?.filter((img) => img.type !== 0)
                .sort((a, b) => a.prod_image_id - b.prod_image_id)
                .map((img) => (
                  <img src={img.image_path} style={{ width: '100%' }} />
                ))
            : ''}
          <Text textAlign={'center'}>{product.description}</Text>
        </TabPanel>
        <TabPanel>
          <DeliveryInfo />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
