import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { ProfileLayout } from './ProfileLayout'
import { useEffect, useRef, useState } from 'react'
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  useAddOrModifyAddressQuery,
  useChangePasswordQuery,
  useUserInfoModifyQuery,
} from 'common/api/domains/profile/profileQuery'
import {
  ChangePaswordDto,
  ProfileModifyDto,
  UserAddressProps,
} from 'domain/profile'
import { LockIcon } from '@chakra-ui/icons'
import hashingString from 'common/util/hashStringUtils'
import { log } from 'console'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { customAlert, isEmpty, isLogin } from 'common/util/string-utils'
import Swal from 'sweetalert2'

export function ModifyProfile() {
  const isMobile = useRecoilValue(clientIsMobile)

  const location = useLocation()
  const { mutate: changePasswordMutate } = useChangePasswordQuery()
  const { mutate: userInfoModifyMutate } = useUserInfoModifyQuery()
  const modifyData: UserAddressProps = location.state
  const navigate = useNavigate()
  const [firstInput, setFirstInput] = useState('')
  const [secondInput, setSecondInput] = useState('')
  const [thirdInput, setThirdInput] = useState('')

  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [prevPassword, setPrevPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  useEffect(() => {
    isLogin(navigate)
    let phone = localStorage.getItem('phone') || ''
    setUserName(localStorage.getItem('name') || '')
    setFirstInput(phone.slice(0, 3))
    setSecondInput(phone.slice(4, 8))
    setThirdInput(phone.slice(9, 13))
  }, [])
  const changePassword = () => {
    if (newPassword !== confirmPassword) {
      customAlert('새로운 비밀번호와 확인 비밀번호가 일치하지 않습니다')
      return
    }
    if (newPassword.length < 8 || newPassword.length > 12) {
      customAlert('비밀번호를 8자 이상, 16자 이하로 입력하여 주십시오.')
      return
    }

    let changePasswordDto: ChangePaswordDto = {
      newPassword: hashingString(newPassword),
      prevPassword: prevPassword,
    }
    changePasswordMutate(changePasswordDto, {
      onSuccess: (data) => {
        navigate(0)
      },
      onError: (error) => {
        customAlert('Нууц үг солигдож чадсангүй.')
      } /*비밀번호 변경에 실패했습니다.*/,
      onSettled: (data, error) => {
        // setFormData(data)
      },
    })
  }

  const changeInfo = () => {
    let modifyDto: ProfileModifyDto = {
      newUsername: userName,
      newPhone: firstInput + '-' + secondInput + '-' + thirdInput,
    }
    userInfoModifyMutate(modifyDto, {
      onSuccess: (data) => {
        localStorage.setItem('jwt-token', data.accessToken)
        localStorage.setItem('name', data.name)
        localStorage.setItem('phone', data.phone)
        navigate('/management/profile/delivery')
      },
      onError: (error) => {},
      onSettled: (data, error) => {
        // setFormData(data)
      },
    })
  }
  const [isOpen, setIsOpen] = useState(false)

  const onToggleModal = () => {
    setIsOpen((prev) => !prev)
  }

  const handleComplete = () => {
    onToggleModal() // 주소창은 자동으로 사라지므로 모달만 꺼주면 된다.
  }
  return (
    <ProfileLayout pageName="МЭДЭЭЛЭЛ ЗАСАХ">
      {' '}
      {/*회원정보수정*/}
      <FormControl alignItems="center" w={'100%'}>
        <Table variant="simple">
          <Tr borderTop={'1px solid #E2E8F0'}>
            <Td
              id={'prop'}
              p={1}
              textAlign={'center'}
              fontSize={isMobile ? '14px' : '14px'}
              bgColor={'#f7f7f7'}
              color={'#171717'}
              fontWeight={600}
            >
              НЭР
            </Td>
            {/*이름*/}
            <Td>
              <Input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                fontSize={isMobile ? '16px' : '14px'}
                color={'#171717'}
                fontWeight={600}
              />
            </Td>
          </Tr>
          <Tr>
            <Td
              id={'prop'}
              p={1}
              textAlign={'center'}
              fontSize={isMobile ? '12px' : '14px'}
              color={'#171717'}
              fontWeight={600}
            >
              И-МЭЙЛ
            </Td>{' '}
            {/*이메일*/}
            <Td>
              {isMobile ? (
                <>
                  <Text color={'#171717'} fontWeight={600}>
                    {localStorage.getItem('email') || ''}
                  </Text>
                </>
              ) : (
                <Input
                  type="email"
                  value={localStorage.getItem('email') || ''}
                  fontSize={isMobile ? '16px' : '14px'}
                  bgColor={'lightgray'}
                  readOnly
                />
              )}
            </Td>
          </Tr>

          <Tr>
            <Td
              w={'20%'}
              id={'prop'}
              p={1}
              textAlign={'center'}
              fontSize={isMobile ? '12px' : '14px'}
              color={'#171717'}
              fontWeight={600}
            >
              УТАС
            </Td>{' '}
            {/*전화번호*/}
            <Td w={'80%'}>
              <HStack spacing={2}>
                <Input
                  value={firstInput}
                  fontSize={isMobile ? '14px' : ''}
                  maxLength={3}
                  type="tel"
                  onChange={(e) => setFirstInput(e.target.value)}
                />
                <Text>-</Text>
                <Input
                  value={secondInput}
                  fontSize={isMobile ? '14px' : ''}
                  maxLength={4}
                  type="tel"
                  onChange={(e) => setSecondInput(e.target.value)}
                />
                <Text>-</Text>
                <Input
                  value={thirdInput}
                  fontSize={isMobile ? '14px' : ''}
                  maxLength={4}
                  type="tel"
                  onChange={(e) => setThirdInput(e.target.value)}
                />
              </HStack>
            </Td>
          </Tr>
        </Table>
        <div
          style={{
            alignItems: 'right',
            textAlign: 'center',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Button
            color="black"
            bg="white"
            w={isMobile ? '90%' : 40}
            fontSize={isMobile ? '12px' : '14px'}
            onClick={() => handleComplete()}
          >
            НУУЦ ҮГ СОЛИХ
          </Button>{' '}
          {/*비밀번호변경*/}
        </div>
      </FormControl>
      <div style={{ textAlign: 'center', marginTop: '0' }}>
        {/*<Button
          colorScheme="blue"
          variant="outline"
          w={isMobile ? '90%' : 40}
          onClick={() => navigate(-1)}
          mr={2}
        >
          ХААХ
        </Button> 닫기*/}
        <Button
          colorScheme="blue"
          w={isMobile ? '90%' : 40}
          onClick={changeInfo}
        >
          ХАДГАЛАХ
        </Button>{' '}
        {/*저장*/}
      </div>
      <div style={{ textAlign: 'center', marginTop: '30px' }}>
        <Button
          bg="white"
          color={'gray'}
          w={isMobile ? '90%' : 40}
          fontSize={isMobile ? '10px' : '14px'}
          onClick={() => navigate('/auth/sign-out')}
        >
          БҮРТГЭЛЭЭ ЦУЦЛАХ
        </Button>{' '}
        {/*회원탈퇴*/}
      </div>
      <Modal isOpen={isOpen} onClose={onToggleModal} size={'xl'}>
        <ModalOverlay />
        <ModalContent textAlign={'center'} w={'90%'}>
          <ModalHeader>
            <LockIcon w={55} h={55} color="blue.700" mt={2} />
            <Text fontSize={'md'} mt={3}>
              Бүртгэлээ дор хаяж 8 тэмдэгтээс бүрдэх өвөрмөц нууц үгээр
              хамгаалаарай.
            </Text>{' '}
            {/*8자 이상의 고유한 비밀번호로 계정을 보호하세요.*/}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize={isMobile ? 'xs' : ''}>
            <Box>
              <Text
                display={'inline-block'}
                w={'100%'}
                textAlign={'left'}
                mr={5}
                mb={2}
              >
                ОДОО БАЙГАА НУУЦ ҮГ
              </Text>{' '}
              {/*기존 비밀번호*/}
              <Input
                type="password"
                display={'inline-block'}
                w={isMobile ? '100%' : '100%'}
                value={prevPassword}
                onChange={(e) => setPrevPassword(e.target.value)}
              />
            </Box>
            <Box mt={5}>
              <Text
                display={'inline-block'}
                w={'100%'}
                textAlign={'left'}
                mr={5}
                mb={2}
              >
                ШИНЭ НУУЦ ҮГ
              </Text>{' '}
              {/*새비밀번호*/}
              <Input
                type="password"
                placeholder="ШИНЭ НУУЦ ҮГ(8-аас 20 тэмдэгт)"
                display={'inline-block'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                w={isMobile ? '100%' : '100%'}
                sx={
                  isMobile
                    ? {
                        '::placeholder': {
                          fontSize: '14px', // Adjust the font size as needed
                          color: 'gray.400', // Optional: Adjust the placeholder color
                        },
                      }
                    : ''
                }
              />
            </Box>
            <Box mt={5}>
              <Text
                display={'inline-block'}
                w={'100%'}
                textAlign={'left'}
                mr={5}
              >
                НУУЦ ҮГ ДАХИН БИЧИХ
              </Text>{' '}
              {/*비밀번호재입력*/}
              <Input
                type="password"
                placeholder="ШИНЭ НУУЦ ҮГЭЭ ДАХИН ОРУУЛНА УУ." /*새 비밀번호를 다시 입력하세요*/
                display={'inline-block'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                w={isMobile ? '100%' : '100%'}
                mb={5}
                sx={
                  isMobile
                    ? {
                        '::placeholder': {
                          fontSize: '14px', // Adjust the font size as needed
                          color: 'gray.400', // Optional: Adjust the placeholder color
                        },
                      }
                    : ''
                }
              />
            </Box>
          </ModalBody>
          <ModalFooter w={'100%'}>
            <Button
              colorScheme="blue"
              w={'100%'}
              mb={5}
              onClick={changePassword}
            >
              СОЛИХ
            </Button>
            {/*변경하기*/}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ProfileLayout>
  )
}
