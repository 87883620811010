import { OrderFomikValuesType } from 'views/purchase/Order/type'
import * as Yup from 'yup'

export const OrderValidationSchema = Yup.object<OrderFomikValuesType>().shape({
  address: Yup.object().shape({
    name: Yup.string().required(
      'Хүлээн авагчийн нэрийг оруулна уу',
    ) /*받는 분 이름을 입력해주세요.*/,
    postcode: Yup.string().required('Та хаягаа оруулна уу') /*우편번호*/,
    address1: Yup.string().required('Та хаягаа оруулна уу') /*주소입력*/,
    address2: Yup.string().required(
      'Дэлгэрэнгүй хаягаа оруулна уу',
    ) /*주소상세입력*/,
    phone: Yup.string()
      .required('Утасны дугаараа оруулна уу') /*전화번호 입력*/
      .test(
        'valid-phone',
        'Хүчинтэй утасны дугаар оруулна уу' /*유효한 전화번호를 입력해주세요*/,
        (value) => {
          if (!value) return false
          const parts = value.split('-')
          return parts.length === 3 && parts.every((part) => part.trim() !== '')
        },
      ),
  }),
  depositorName: Yup.string().required(''),
  /*입금자명을 입력해주세요.*/
})
