import { UserRole } from 'common/util/enum'
import { UserModel } from 'domain/user/models'
import { UserResponseDTO } from 'domain/user/response.dto'
import React, { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { AuthType, authState, userState } from 'state/auth/atoms'

export type LoginSuccessVo = {
  user: UserResponseDTO
  access_token: string
  refresh_token: string
  phone: string
  name: string
  email: string
  default_address_id: number
}

export const useAuth = () => {
  const [auth, setAuth] = useRecoilState<AuthType | null>(authState)
  const [user, setUser] = useRecoilState<UserModel | null>(userState)

  const login = useCallback((loginData: LoginSuccessVo) => {
    setAuth({
      jwtToken: loginData.access_token,
      refreshToken: loginData.refresh_token,
      loginAt: new Date(),
    })
    // <<<<<<< Updated upstream
    //     setUser(new UserModel(loginData.user))
    //     localStorage.setItem('user', JSON.stringify(loginData.user))
    //     sessionStorage.setItem('jwt-token', loginData.access_token)
    //     sessionStorage.setItem('refresh-token', loginData.refresh_token)
    //     sessionStorage.setItem('phone', loginData.phone)
    //     sessionStorage.setItem('name', loginData.name)
    //     sessionStorage.setItem('email', loginData.email)
    //     sessionStorage.setItem('default-address', loginData.default_address_id + '')
    // =======
    const userModel = new UserModel(loginData.user)
    setUser(userModel)
    localStorage.setItem('user', JSON.stringify(userModel))
    localStorage.setItem('mall-id', '1')
    localStorage.setItem('jwt-token', loginData.access_token)
    localStorage.setItem('refresh-token', loginData.refresh_token)
    localStorage.setItem('phone', loginData.phone)
    localStorage.setItem('name', loginData.name)
    localStorage.setItem('email', loginData.email)
    localStorage.setItem('default-address', loginData.default_address_id + '')
    // >>>>>>> Stashed changes
  }, [])

  const logout = useCallback(() => {
    setAuth(null)
    setUser(null)
    localStorage.clear()
    localStorage.clear()
  }, [])

  const refreshUserState = useCallback(() => {
    const userModel = localStorage.getItem('user')

    if (userModel) {
      setUser(new UserModel(JSON.parse(userModel)))
    }
  }, [])

  return { auth, user, login, logout, refreshUserState }
}
