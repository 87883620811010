import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { ProductInformationTabs } from './ProductInformationTabs'
import { useProductQuery } from 'common/api/domains/goods/goodsQuery'
import { ProductTopInfo } from './ProductTopInfo'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

type ProductDetailProps = {
  productId: number
}

export const ProductDetailMobile = ({ productId }: ProductDetailProps) => {
  const { data: product } = useProductQuery(productId)
  const category = product?.category
  if (!product || !category) return null
  return (
    <Box w={'375px'}>
      <Grid templateColumns={'1fr 1fr 1fr'}>
        <GridItem>
          <Link to={`/goods/categories/${category.id}/products`}>
            <ArrowBackIcon ml={2} />
          </Link>
        </GridItem>
        <GridItem
          display={'flex'}
          justifyContent={'center'}
          fontWeight={'bold'}
          fontSize={'18px'}
        >
          {/*제품 상세*/}
        </GridItem>
        <GridItem />
      </Grid>
      <Box h={'64px'} />
      <ProductTopInfo product={product} />
      <Box h={'64px'} />
      <ProductInformationTabs product={product} />
    </Box>
  )
}
