import {
  AddressFromImageResponseDTO,
  AddressResponseDTO,
  UserResponseDTO,
} from './response.dto'

export class UserModel {
  id!: number
  facebookToken?: string
  email?: string
  name?: string
  password?: string
  phone?: string
  role!: number
  defaultAddressId?: number
  createdAt?: Date
  updatedAt?: Date

  constructor(data: UserResponseDTO) {
    this.id = data.id
    this.facebookToken = data.facebook_token
    this.email = data.email
    this.name = data.name
    this.password = data.password
    this.phone = data.phone
    this.role = data.role
    this.defaultAddressId = data.default_address_id
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }
}

export class AddressModel {
  id!: number
  userId!: number
  address1!: string
  address2!: string
  postcode!: string
  name!: string
  created_at!: Date
  updated_at?: Date

  constructor(data: AddressResponseDTO) {
    this.id = data.id
    this.userId = data.user_id
    this.address1 = data.address1
    this.address2 = data.address2
    this.postcode = data.postcode
    this.name = data.name
    this.created_at = data.created_at
    this.updated_at = data.updated_at
  }
}

export class AddressFromImageModel {
  address1!: string
  address2!: string
  postcode!: string
  name!: string

  constructor(data: AddressFromImageResponseDTO) {
    this.address1 = data.address1
    this.address2 = data.address2
    this.postcode = data.postcode
    this.name = data.name
  }
}
