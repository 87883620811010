import { atom } from 'recoil'
import { UserAddressProps } from 'domain/profile'
import { createAxiosInstance } from 'common/config/axios.config'

const axiosInstance = createAxiosInstance(true)

export const profileState = atom<number>({
  key: 'profileState',
  default: 0,
})

export function addAddressFetcher(data: UserAddressProps) {
  return axiosInstance.post(`/profile/address`, data).then((response) => {
    return response.data
  })
}

export const checkAddressState = atom<Int32List>({
  key: 'checkAddressState',
  default: [],
})
