import { Box, Text, Textarea } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
export function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = useState('')
  useEffect(() => {
    fetch('/privacyPolicy.txt')
      .then((response) => response.text())
      .then((data) => setPrivacyPolicy(data))
      .catch((error) => console.error('Error fetching the text file:', error))
  }, [])
  return (
    <Box pb={10}>
      {' '}
      <Box textAlign={'center'} width="100%" py={10}>
        <Text as={'b'} fontSize={'18px'}>
          АЮУЛГҮЙ АЖИЛЛАГАА
        </Text>{' '}
        {/*개인정보처리방침*/}
      </Box>
      <div
        style={{ fontSize: '12px', padding: '10px' }}
        dangerouslySetInnerHTML={{
          __html: privacyPolicy.replace(/\n/g, '<br/>'),
        }}
      />
    </Box>
  )
}
