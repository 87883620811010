import {
  useNumberInput,
  HStack,
  Button,
  Input,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
} from '@chakra-ui/react'
import React, { ReactNode, useEffect } from 'react'
export type CustomModalParammeter = {
  onClose: () => void
  submit: () => void
  isOpen: boolean
  closeButtonMessage: string
  submitButtonMessage: string
  message: string
}
export const CustomModal = ({
  onClose,
  submit,
  isOpen,
  message,
  submitButtonMessage,
  closeButtonMessage,
}: CustomModalParammeter) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w={'100%'}>
          <ModalHeader></ModalHeader>

          <ModalBody className="custom-modal" px={0}>
            <Box textAlign={'center'} my={5}>
              {message}
            </Box>
          </ModalBody>

          <ModalFooter p={0} h={100}>
            <Button colorScheme="blue" onClick={submit} w={'50%'} ml={5} mr={5}>
              {submitButtonMessage}
            </Button>
            <Button
              colorScheme="blue"
              onClick={onClose}
              variant="outline"
              w={'50%'}
              mr={5}
            >
              {closeButtonMessage}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
