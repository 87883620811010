import { Divider, Flex, Button, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { OrderItem } from './OrderItem'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { useRecoilValue } from 'recoil'
import {
  orderProductFormsState,
  isOrderFromCartState,
} from 'state/purchase/atoms'

type OrderProductListProps = {}

export const OrderProductList = ({}: OrderProductListProps) => {
  const orderProductForms = useRecoilValue(orderProductFormsState)
  const isOrderFromCart = useRecoilValue(isOrderFromCartState)
  const { goTo: goToCart } = usePrivateNavigate('/purchase/basket')

  return (
    <Box mb={4} pr={3} pl={3}>
      <Text fontWeight={'bold'}>БҮТЭЭГДЭХҮҮН</Text> {/*주문상품*/}
      <Divider borderWidth="0.5px" borderColor={'gray'} my={4} />
      {orderProductForms.map((orderProductForm, index) => (
        <OrderItem key={index} orderProductForm={orderProductForm} />
      ))}
      {isOrderFromCart && (
        <Flex flexDirection={'column'} mt={4}>
          <Text fontSize={14}>
            *Сагснаас захиалсан бүтээгдэхүүнийхээ тоог өөрчлөх боломжтой.
          </Text>{' '}
          {/*주문상품 수량 변경은 장바구니 페이지에서 가능합니다.*/}
          <Button
            colorScheme="gray"
            variant={'outline'}
            size={'md'}
            ml={2}
            onClick={() => goToCart()}
          >
            САГС РУУ
          </Button>{' '}
          {/*장바구니로 이동*/}
        </Flex>
      )}
    </Box>
  )
}
