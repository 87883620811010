import { Box, Divider, Grid, GridItem, Input, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { HintText } from 'views/component/HintText'
import { OrderFomikValuesType } from '../type'
import { useOwnerAccount } from 'common/api/domains/common/commonQuery'

type OrderInfoProps = {}

export const OrderInfo = ({}: OrderInfoProps) => {
  const { values, setFieldValue, errors } =
    useFormikContext<OrderFomikValuesType>()
  const { data: ownerAccount, isLoading } = useOwnerAccount()

  useEffect(() => {
    if (isLoading || !ownerAccount) return
    const [accountNumber, accountBankName, accountHolder] =
      ownerAccount.split(' ')
    setFieldValue('accountBankName', accountBankName || '')
    setFieldValue('accountHolder', accountHolder || '')
    setFieldValue('accountNumber', accountNumber || '')
  }, [isLoading])

  return (
    <Box>
      <Text fontSize={'lg'} fontWeight={'bold'}>
        ТӨЛБӨРИЙН НӨХЦӨЛ
      </Text>{' '}
      {/*결제정보*/}
      <Divider borderWidth={2} borderColor={'gray'} />
      <Grid templateColumns={'128px 1fr'} rowGap={4} mt={8}>
        <GridItem mt={2} color={'#505050'}>
          *ШИЛЖҮҮЛЭХ ДАНС
        </GridItem>{' '}
        {/*입금은행*/}
        <GridItem
          mt={2}
        >{`${values.accountBankName} ${values.accountNumber} | ${values.accountHolder}`}</GridItem>
        <GridItem mt={2} color={'#505050'}>
          *ДАНС ЭЗЭМШИГЧИЙН НЭР
        </GridItem>{' '}
        {/*입금자명*/}
        <GridItem display={'flex'} flexDirection={'column'}>
          <Input
            value={values.depositorName}
            onChange={(e) => setFieldValue('depositorName', e.target.value)}
          />
          <HintText
            hintType={'error'}
            hint={errors.depositorName}
            preOccupySpace={false}
          />
          <HintText
            hintType={'error'}
            hint={
              'Данс эзэмшигчийн нэрийг зөв бичиж оруулна уу, тэдгээр нь таарвал гүйлгээг 1 минутын дотор баталгаажуулна. Хэрэв энэ дээр бичсэн нэр, хадгаламж эзэмшигчийн нэр өөр байвал баталгаажуулалт удааширна.'
            }
          />
        </GridItem>
      </Grid>
    </Box>
  )
}
