import {
  Flex,
  Grid,
  GridItem,
  Divider,
  Text,
  Box,
  Image,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { MoneyText } from 'views/component/MoneyText'
import { ProductTempCart } from './ProductTempCart'
import { Formik } from 'formik'
import { CostSummary } from './CostSummary'
import { CreateCartRequestDTO } from 'domain/purchase/request.dto'
import { ProductModel } from 'domain/goods/model'
import { useGetCost } from 'common/api/domains/purchase/purchaseQuery'
import { useRecoilValue } from 'recoil'
import { orderProductFormsState } from 'state/purchase/atoms'
import { useAuth } from 'common/hooks/useAuth'

type ProductTopInfoProps = {
  product: ProductModel
}

export const ProductTopInfo = ({ product }: ProductTopInfoProps) => {
  const { user } = useAuth()
  const orderProductForms = useRecoilValue(orderProductFormsState)
  const { data: fetchedCostData, refetch: refetchCost } = useGetCost({
    products: orderProductForms.map(({ productId, count }) => ({
      productId,
      count,
    })),
  })

  return (
    <Formik
      initialValues={
        {
          productId: product.id,
          count: 1,
        } as Omit<CreateCartRequestDTO, 'userId'>
      }
      onSubmit={() => {}}
    >
      {({ values }) => {
        return (
          <>
            <Image src={product.thumbnail?.path} w={'95%'} h={'350px'} ml={3} />
            <Flex p={4} flexDirection={'column'} justifyContent={'center'}>
              <Text fontSize={'20px'} my={2} fontWeight={'bold'}>
                {product.name}
              </Text>
              <Flex flexDirection={'column'}>
                <Divider borderColor={'#505050'} mb={3} />
                <Box>
                  <Flex
                    h={'36px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text>ҮНЭ</Text> {/*가격*/}
                    <MoneyText amount={product.price} unit={'₩'} />
                  </Flex>
                  <Divider />
                  <Flex
                    h={'36px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text>ХЭМЖЭЭ</Text> {/*기준*/}
                    <Text>{product.unit_size}</Text>
                  </Flex>
                  <Divider />
                  <Flex
                    h={'36px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text>ХҮРГЭЛТ</Text> {/*배송*/}
                    <MoneyText
                      amount={fetchedCostData?.delivery || 0}
                      unit={'₩'}
                    />
                  </Flex>
                </Box>
                <Divider my={4} borderColor={'#505050'} />
                <ProductTempCart product={product} />
                <Divider my={4} borderColor={'#505050'} />
                <CostSummary product={product} />
              </Flex>
            </Flex>
          </>
        )
      }}
    </Formik>
  )
}
