import { Image } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

export const Logo = ({}) => {
  return (
    <Link to="/">
      <Image className="logo" />
    </Link>
  )
}
