export class OrderProductForm {
  public productName!: string
  public price!: number
  public count!: number
  public productId!: number
  public unit!: string

  constructor({
    productName,
    price,
    count,
    productId,
    unit,
  }: {
    productName: string
    price: number
    count: number
    productId: number
    unit: string
  }) {
    this.productName = productName
    this.price = price
    this.count = count
    this.productId = productId
    this.unit = unit
  }
}
