import { Flex, Button, Box, Text } from '@chakra-ui/react'
import { ProductModel } from 'domain/goods/model'
import { CreateCartRequestDTO } from 'domain/purchase/request.dto'
import { useFormikContext } from 'formik'
import React from 'react'
import { CountInput } from 'views/component/CountInput'
import { MoneyText } from 'views/component/MoneyText'

type ProductTempCartProps = {
  product: ProductModel
}

export const ProductTempCart = ({ product }: ProductTempCartProps) => {
  const { values, setFieldValue } = useFormikContext<CreateCartRequestDTO>()

  const updateCart = (count: string) => {
    setFieldValue('count', +count)
  }

  return (
    <Box px={2} py={4}>
      <Flex justifyContent={'space-between'} mb={4} mr={4}>
        <Text fontSize={18}>{product.name}</Text>
        <MoneyText
          amount={product.price}
          unit={'₩'}
          textProps={{ fontSize: 18 }}
        />
      </Flex>
      <Flex justifyContent={'space-between'}>
        <Box />
        <Flex>
          <CountInput value={values.count} onChange={updateCart} min={1} />
        </Flex>
      </Flex>
    </Box>
  )
}
