import axios from 'axios'
import { createAxiosInstance } from 'common/config/axios.config'
import { ImageModel } from 'domain/image/model'
import { ImageResponseDTO } from 'domain/image/response.dto'

const imageAxiosInstance = createAxiosInstance(true)

export function uploadImage(formData: FormData): Promise<ImageModel> {
  return imageAxiosInstance
    .post<ImageResponseDTO>(`/images/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return new ImageModel(response.data)
    })
}
