import {
  Grid,
  GridItem,
  Input,
  VStack,
  Flex,
  Button,
  HStack,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { AddressForm, useAddress } from 'common/hooks/useAddress'
import { phoneIdentificationNumbers } from 'common/util/phone'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { HintText } from 'views/component/HintText'
import { PostcodeModal } from 'views/component/PostcodeModal'
import { OrderFomikValuesType } from 'views/purchase/Order/type'

type ManualAddressProps = {
  handleAddress: (address: AddressForm) => void
  isActive: boolean
}

export const ManualAddress = ({
  handleAddress,
  isActive,
}: ManualAddressProps) => {
  const { errors } = useFormikContext<OrderFomikValuesType>()
  const manualAddress = useAddress()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (!isActive) return
    handleAddress(manualAddress.address)
  }, [
    isActive,
    manualAddress.address.name,
    manualAddress.address.postcode,
    manualAddress.address.address1,
    manualAddress.address.address2,
    manualAddress.address.phone,
  ])

  return (
    <Grid templateColumns={'84px 1fr'} rowGap={4} mb={8}>
      <GridItem pt={2} fontSize={'14px'}>
        *ХҮЛЭЭН АВАГЧ
      </GridItem>{' '}
      {/*수령인*/}
      <GridItem>
        <Input
          size={'sm'}
          value={manualAddress.address.name}
          onChange={(e) => manualAddress.onChangeName(e.target.value)}
        />
        <HintText
          hintType={'error'}
          hint={errors.address?.name}
          fontSize="xs"
        />
      </GridItem>
      <GridItem pt={2} fontSize={'14px'}>
        *ХАЯГ
      </GridItem>
      {/*주소*/}
      <GridItem>
        <Flex flexDirection={'column'}>
          <Flex w={'100%'}>
            <Input
              size={'sm'}
              placeholder="БҮСИЙН КОД"
              mr={2}
              isDisabled
              value={manualAddress.address.postcode}
            />{' '}
            {/*우편번호*/}
            <Button
              size={'sm'}
              fontSize={'12px'}
              colorScheme="blue"
              onClick={onOpen}
              color={'#ffffff'}
            >
              ХАЙХ
            </Button>{' '}
            {/*주소검색*/}
            <PostcodeModal
              isOpen={isOpen}
              onClose={onClose}
              onComplete={(address) => {
                manualAddress.onChangePostcode(address.zonecode)
                manualAddress.onChangeAddress1(address.address)
                onClose()
              }}
            />
          </Flex>
          <HintText
            hintType={'error'}
            hint={errors.address?.postcode}
            fontSize="xs"
          />
          <Input
            size={'sm'}
            value={manualAddress.address.address1}
            onChange={(e) => manualAddress.onChangeAddress1(e.target.value)}
            placeholder="ХАЯГ"
          />{' '}
          {/*기본주소*/}
          <HintText
            hintType={'error'}
            hint={errors.address?.address1}
            fontSize="xs"
          />
          <Input
            size={'sm'}
            value={manualAddress.address.address2}
            onChange={(e) => manualAddress.onChangeAddress2(e.target.value)}
            placeholder="ҮЛДСЭН ХАЯГ"
          />{' '}
          {/*나머지주소*/}
          <HintText
            hintType={'error'}
            hint={errors.address?.address2}
            fontSize="xs"
          />
        </Flex>
      </GridItem>
      <GridItem pt={2} fontSize={'14px'}>
        *УТАС
      </GridItem>{' '}
      {/*휴대전화*/}
      <GridItem>
        <HStack>
          <Select
            size={'sm'}
            value={manualAddress.address.phone.split('-')[0]}
            onChange={(e) => manualAddress.onChangePhone(e.target.value, 0)}
            placeholder="---"
            w={20}
          >
            {phoneIdentificationNumbers.map((v, index) => (
              <option key={index} value={v}>
                {v}
              </option>
            ))}
          </Select>
          <Text>-</Text>
          <Input
            value={manualAddress.address.phone.split('-')[1]}
            onChange={(e) => manualAddress.onChangePhone(e.target.value, 1)}
            size={'sm'}
            type="number"
            w={16}
          />
          <Text>-</Text>
          <Input
            value={manualAddress.address.phone.split('-')[2]}
            onChange={(e) => manualAddress.onChangePhone(e.target.value, 2)}
            size={'sm'}
            type="number"
            w={16}
          />
        </HStack>
        <HintText
          hintType={'error'}
          hint={errors.address?.phone}
          fontSize="xs"
        />
      </GridItem>
    </Grid>
  )
}
