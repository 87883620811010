import React, { useEffect, useState } from 'react'

export const useEnv = () => {
  const [isReady, setIsReady] = useState<boolean>(false)

  const setEnvironment = () => {
    const mallId = process.env.REACT_APP_API_MALL_ID
    if (!mallId) return
    localStorage.setItem('mall-id', mallId)
  }

  const getValidation = () => {
    const mallId = localStorage.getItem('mall-id')
    if (!mallId) return false
    return true
  }

  useEffect(() => {
    setEnvironment()
    setIsReady(getValidation())
    const handleStorageChange = () => {
      setIsReady(getValidation())
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  return { isReady }
}
