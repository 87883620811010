import {
  Flex,
  Grid,
  GridItem,
  Divider,
  Text,
  Box,
  Image,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { MoneyText } from 'views/component/MoneyText'
import { ProductTempCart } from './ProductTempCart'
import { Formik } from 'formik'
import { CostSummary } from './CostSummary'
import { CreateCartRequestDTO } from 'domain/purchase/request.dto'
import { ProductModel } from 'domain/goods/model'
import { useRecoilValue } from 'recoil'
import { orderProductFormsState } from 'state/purchase/atoms'
import { useGetCost } from 'common/api/domains/purchase/purchaseQuery'
import { useAuth } from 'common/hooks/useAuth'

type ProductTopInfoProps = {
  product: ProductModel
}

export const ProductTopInfo = ({ product }: ProductTopInfoProps) => {
  const { user } = useAuth()
  const orderProductForms = useRecoilValue(orderProductFormsState)
  const { data: fetchedCostData, refetch: refetchCost } = useGetCost({
    products: orderProductForms.map(({ productId, count }) => ({
      productId,
      count,
    })),
  })

  return (
    <Formik
      initialValues={
        {
          productId: product.id,
          count: 1,
        } as Omit<CreateCartRequestDTO, 'userId'>
      }
      onSubmit={() => {}}
    >
      {({ values }) => {
        return (
          <Flex justifyContent={'center'}>
            <Grid templateColumns={'1fr 1fr'} columnGap={8}>
              <GridItem>
                <Image src={product.thumbnail?.path} w={'552px'} h={'552px'} />
              </GridItem>
              <GridItem display={'flex'} flexDirection={'column'} w={'566px'}>
                <Text fontSize={28} fontWeight={'bold'}>
                  {product.name}
                </Text>
                <Divider my={4} borderColor={'#606060'} />
                <Flex
                  h={'42px'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  px={2}
                >
                  <Text fontSize={18}>ҮНЭ</Text> {/*가격*/}
                  <MoneyText
                    amount={product.price}
                    unit={'₩'}
                    textProps={{ fontSize: 18 }}
                  />
                </Flex>
                <Flex
                  h={'42px'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  px={2}
                >
                  <Text fontSize={18}>ХЭМЖЭЭ</Text> {/*기준*/}
                  <Text fontSize={18}>{product.unit_size}</Text>
                </Flex>
                <Flex
                  h={'42px'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  px={2}
                >
                  <Text fontSize={18}>ХҮРГЭЛТ</Text> {/*배송*/}
                  <MoneyText
                    amount={fetchedCostData?.delivery || 0}
                    unit={'₩'}
                    textProps={{ fontSize: 18 }}
                  />
                </Flex>
                <Divider />
                <ProductTempCart product={product} />
                <Divider my={2} />
                <CostSummary product={product} />
              </GridItem>
              <GridItem />
            </Grid>
          </Flex>
        )
      }}
    </Formik>
  )
}
