import React, { useCallback, useMemo } from 'react'
import { NavigateOptions, useNavigate } from 'react-router-dom'
import { useAuth } from './useAuth'
import { jsonToQueryString } from 'common/util/uri'

export const usePrivateNavigate = (prefixPath: string = '') => {
  const navigate = useNavigate()
  const { user } = useAuth()

  const href = useMemo(() => {
    if (!user?.id) return '/login'
    return `/users/${user.id}/${prefixPath}/`.replaceAll('//', '/')
  }, [user?.id, prefixPath])

  const goTo = useCallback(
    (payload?: {
      path?: string
      query?: { [key: string]: any }
      options?: NavigateOptions
    }) => {
      return navigate(
        `${href}/${payload?.path ?? ''}/?${jsonToQueryString(payload?.query ?? {})}`.replace(
          /\/+/g,
          '/',
        ),
        payload?.options,
      )
    },
    [href],
  )

  const goBack = useCallback(() => {
    return navigate(-1)
  }, [])

  return {
    goTo,
    goBack,
    href,
  }
}
