import {
  CategoryResponseDTO,
  ProductImageDTO,
  ProductResponseDTO,
  ThumbnailResponseDTO,
} from './response.dto'

export class CategoryModel {
  id!: number
  name!: string
  seq!: number
  createdAt!: Date
  updatedAt!: Date

  constructor({ id, name, seq, created_at, updated_at }: CategoryResponseDTO) {
    this.id = id
    this.name = name
    this.seq = seq
    this.createdAt = created_at
    this.updatedAt = updated_at
  }
}

export class ProductImageModel {
  prod_image_id!: number
  image_path!: string
  type!: number
  prod_id!: Date
  mall_id!: Date

  constructor({
    prod_image_id,
    image_path,
    type,
    prod_id,
    mall_id,
  }: ProductImageDTO) {
    this.prod_image_id = prod_image_id
    this.image_path = image_path
    this.type = type
    this.prod_id = prod_id
    this.mall_id = mall_id
  }
}
export class ProductModel {
  id!: number
  name!: string
  description!: string
  price!: number
  category?: CategoryModel
  thumbnail?: ThumbnailModel
  product_images?: ProductImageModel[]
  minOrder!: number
  stocks!: number
  seq!: number
  unit!: string
  unit_size!: string
  active!: boolean
  is_main_display!: boolean
  main_seq!: number
  createdAt?: Date
  updatedAt?: Date

  constructor({
    id,
    name,
    description,
    price,
    category,
    thumbnail,
    product_images,
    min_order,
    stocks,
    seq,
    unit,
    unit_size,
    active,
    is_main_display,
    main_seq,
    created_at,
    updated_at,
  }: ProductResponseDTO) {
    this.id = id
    this.name = name
    this.description = description
    this.price = price
    this.category = category && new CategoryModel(category)
    this.product_images =
      product_images && product_images.map((img) => new ProductImageModel(img))
    this.minOrder = min_order
    this.stocks = stocks
    this.seq = seq
    this.unit = unit
    this.unit_size = unit_size
    this.active = active
    this.main_seq = main_seq
    this.is_main_display = is_main_display
    this.createdAt = created_at
    this.updatedAt = updated_at
    this.thumbnail = thumbnail
  }
}

export class ThumbnailModel {
  id!: number
  path!: string
  type!: string
  created_at!: Date
  updated_at!: Date

  constructor({
    id,
    path,
    type,
    created_at,
    updated_at,
  }: ThumbnailResponseDTO) {
    this.id = id
    this.path = path
    this.type = type
    this.created_at = created_at
    this.updated_at = updated_at
  }
}
